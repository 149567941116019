import {
  Button,
  Dialog,
  DialogContent,
  InputLabel,
  Switch,
  // TextField,
  Input,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useBoardContext } from '../../contexts';
import api, { TEmsToken, TKey } from '../../services/ApiService';
import { InputWrapper, Row } from '../../ui';
import { DialogHeader } from '../board-header';
import { Container } from './styles';

export default function SettingsModal({ open, onClose }: any) {
  const { board, updateBoard } = useBoardContext();
  const [emsToken, setEmsToken] = useState<TEmsToken>({ token: null });

  const [key, setKey] = useState<TKey>({ key: null, webhook: null });

  const [liqpay, setLiqpay] = useState<boolean>(false);
  const matches = useMediaQuery('(max-width:480px)');

  async function getConnectEms() {
    try {
      const res = await api.checkConnectEms({
        boardId: board.pk,
      });
      setEmsToken(res as TEmsToken);
    } catch (e) {
      console.log(e);
    }
  }
  async function getConnectLiqPay() {
    try {
      const res = await api.checkConnectLiqPay(board.code);
      setLiqpay(res.keys);
    } catch (e) {
      console.log(e);
    }
  }
  async function getKey() {
    try {
      const res = await api.getKey(board.code);
      setKey({
        key: res.key || null,
        webhook: res.webhook || null,
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getConnectEms();
    getConnectLiqPay();
    getKey()
  }, [])

  const saveColor = async (e: React.FocusEvent<HTMLInputElement>) => {
    console.log(e);
    try {
      await api.editBoard(board.code, { color_background: e.target.value });
      const newBoard = { ...board };
      newBoard.color_background = e.target.value;
      updateBoard(newBoard);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
    };
    const body = {
      username: target.username.value,
      password: target.password.value,
      boardId: board.pk,
    };
    try {
      const res = await api.connectEms(body);
      await synchronizationEms();
      setEmsToken({ token: res.token });
    } catch (error: any) {
      console.log(error);
    }
  }

  const handleSubmitLiqPay = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      liqpay_public: { value: string };
      liqpay_private: { value: string };
    };
    const body = {
      liqpay_public: target.liqpay_public.value,
      liqpay_private: target.liqpay_private.value,
    };
    try {
      const res = await api.connectLiqPay(board.code, body);
      setLiqpay(res.keys);
    } catch (error: any) {
      console.log(error);
    }
  }

  const handleSubmitKey = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const res = await api.createKey(board.code);
      setKey({
        key: res.key || null,
        webhook: res.webhook || null,
      });
    } catch (error: any) {
      console.log(error);
    }
  }

  const disconnectLiqPay = async () => {
    try {
      await api.disconnectLiqPay(board.code);
      location.reload();
    } catch (error: any) {
      console.log(error);
    }
  }

  const synchronizationEms = async () => {
    try {
      await api.synchronizationEms({ boardId: board.pk });
      location.reload();
    } catch (error: any) {
      console.log(error);
    }
  }

  const disconnectEms = async () => {
    try {
      await api.disconnectEms({ boardId: board.pk });
      location.reload();
    } catch (error: any) {
      console.log(error);
    }
  }


  return (
    <Dialog onClose={onClose} open={open}>
      <DialogHeader onClose={onClose} title="Settings" />
      <DialogContent sx={{ minWidth: matches ? '100%' : '400px' }}>
        <Container>
          <Row>
            <span>Background color</span>
            <Input
              type="color"
              sx={{ width: 50, marginLeft: '10px' }}
              onBlur={saveColor}
            />
          </Row>
          <Row>
            <span>Enable Task Spent Hours</span>
            <Switch />
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <b>EMS connect</b>
          </Row>
          {emsToken?.token ? (
            <>
              <div style={{ marginTop: '10px' }}>Token</div>
              <Input
                type="text"
                name="token"
                disabled
                sx={{ width: '100%', marginTop: '10px' }}
                value={emsToken?.token}
              />
              <Button
                sx={{ marginTop: '10px' }}
                onClick={disconnectEms}
              >
                Disconnect
              </Button>
              <Button
                sx={{ marginTop: '10px' }}
                onClick={synchronizationEms}
              >
                Synchronization
              </Button>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <div style={{ marginTop: '10px' }}>Login</div>
              <Input
                type="text"
                name="username"
                sx={{ width: '100%', marginTop: '10px' }}
              />
              <div style={{ marginTop: '10px' }}>Password</div>
              <Input
                type="password"
                name="password"
                sx={{ width: '100%', marginTop: '10px' }}
              />
              <Button sx={{ marginTop: '10px' }} type="submit">Connect</Button>
            </form>
          )}


          <Row style={{ marginTop: '20px' }}>
            <b>LiqPay connect</b>
          </Row>
          {liqpay ? (
            <>
              <div style={{ marginTop: '10px' }}>LiqPay Connected</div>
              <Button
                sx={{ marginTop: '10px' }}
                onClick={disconnectLiqPay}
              >
                Disconnect LiqPay
              </Button>
            </>
          ) : (
            <form onSubmit={handleSubmitLiqPay}>
              <div style={{ marginTop: '10px' }}>Public key</div>
              <Input
                type="text"
                name="liqpay_public"
                sx={{ width: '100%', marginTop: '10px' }}
              />
              <div style={{ marginTop: '10px' }}>Private key</div>
              <Input
                type="text"
                name="liqpay_private"
                sx={{ width: '100%', marginTop: '10px' }}
              />
              <Button sx={{ marginTop: '10px' }} type="submit">Connect</Button>
            </form>
          )}


          <Row style={{ marginTop: '20px' }}>
            <b>Integration</b>
          </Row>
          <form onSubmit={handleSubmitKey}>
            <div style={{ marginTop: '10px' }}>Token</div>
            <Input
              type="text"
              sx={{ width: '100%', marginTop: '10px' }}
              value={key.key}
            />
            <div style={{ marginTop: '10px' }}>Webhook</div>
            <Input
              type="text"
              sx={{ width: '100%', marginTop: '10px' }}
              value={key.webhook}
            />
            <Button sx={{ marginTop: '10px' }} type="submit">Generate</Button>
          </form>

        </Container>
      </DialogContent>
    </Dialog>
  );
}
