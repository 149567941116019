import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import api from '../../services/ApiService';

export function EmailConfirmation() {
  const [searchParams] = useSearchParams();
  const [confirmed, setConfirmed] = useState<boolean | null>(null);

  useEffect(() => {
    (async function () {
      const token = searchParams.get('confirmation_token');
      const userId = searchParams.get('user_id');
      if (token && userId) {
        try {
          await api.confirmEmail(userId, token);
          setConfirmed(true);
        } catch (error: any) {
          console.log(error)
        }
      }
    })();
  }, [searchParams]);

  if (confirmed === null) return null;

  return (
    <div>
      <div>{confirmed ? <p>Email confirmed</p> : <p>Error</p>}</div>
      <div>
        <Link to="/signin">Signin</Link>
      </div>
    </div>
  );
}
