import { ChangeEvent, SyntheticEvent, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/Add';

import { TType } from '../../services/ApiService';

import { AddForm, List, Row } from '../../pages/styles';
import { InputWrapper } from '../../ui/styles';

export type TProps = {
  open: boolean;
  handleClose: () => void;
  handleAdd: (name: string, members: TMember[]) => void;
};

export type TMember = { email: string; type: TType };

export const TypeSelect = () => (
  <InputWrapper style={{ marginTop: 0, marginLeft: '10px' }}>
    <FormLabel htmlFor="type">Type</FormLabel>
    <Select defaultValue="COMMENTATOR" name="type">
      <MenuItem value="ADMIN">Admin</MenuItem>
      <MenuItem value="EDITOR">User</MenuItem>
      <MenuItem value="COMMENTATOR">Viewer</MenuItem>
    </Select>
  </InputWrapper>
);

export default function CreateBoardDialog({
  open,
  handleClose,
  handleAdd,
}: TProps) {
  const [name, setName] = useState('');

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  function onAdd() {
    handleAdd(name, members);
  }

  const [members, setMembers] = useState<TMember[]>([]);

  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
      type: { value: TType };
    };

    setMembers(old => [
      ...old,
      {
        email: target.email.value,
        type: target.type.value,
      },
    ]);
    (e.target as any).reset();
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setMembers([]);
      }}
    >
      <DialogTitle>Create New Board</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To create new board please enter name
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Board name"
          fullWidth
          variant="standard"
          value={name}
          onChange={onChange}
        />
        <div>
          <p>Members</p>
          <AddForm onSubmit={handleSubmit}>
            <InputWrapper>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField type="email" required name="email" />
            </InputWrapper>
            <TypeSelect />
            <IconButton type="submit">
              <AddBoxIcon fontSize="large" sx={{ marginLeft: '10px' }} />
            </IconButton>
          </AddForm>
          <List>
            {members.map(member => (
              <Row key={member.email}>
                <span>{member.email}</span>
                <span>{member.type}</span>
              </Row>
            ))}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onAdd}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}
