import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent, IconButton,
  InputLabel
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { TextareaAutosize } from '@mui/material';
import { TDCard, useBoardContext, useCardContext } from '../../contexts';
import { TTask } from '../task';
import { baseUrl } from '../../services/ApiService';
import { AddWrapper } from './styles';
import useFiles from './useFiles';
import AddAttachments from './add-attachments';
import { TFile } from '../card';
import useAPI from '../../hooks/useAPI';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Wrapper } from '../new-card-modal/styles';
import moment from 'moment';

const pptMIMEtypes =
  'application/mspowerpoint,application/powerpoint,application/vnd.ms-powerpoint,application/x-mspowerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.ppt,.pptx';
const MIMEtypes =
  '.doc,.docx,application/msword,application/ms-doc,application/doc,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/*,.pdf';
const acceptList = `${pptMIMEtypes},${MIMEtypes}`;

export const Attach = ({
  onChange,
  id,
}: {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
}) => (
  <AddWrapper>
    <label htmlFor={id}>
      <AttachFileIcon color="primary" />
    </label>
    <input
      type="file"
      accept={acceptList}
      onChange={onChange}
      style={{ display: 'none' }}
      id={id}
      multiple
    />
  </AddWrapper>
);

export type TDialogProps = {
  open: boolean;
  onClose: () => void;
};

type TTaskDialogProps = TDialogProps & { card: TDCard };

export function AddTaskDialog(props: TTaskDialogProps) {
  const { card } = props;
  const [title, setTitle] = useState('');
  const [deadline, setDeadline] = useState<string | null>(null);

  const { files, onChange, handleDelete, setFiles } = useFiles();

  const { setCard } = useCardContext();
  const { updateBoard, board } = useBoardContext();
  const [time, setTime] = useState(false);
  useEffect(() => {
    moment.locale('en', { week: { dow: 1 } }) // 0-6 (Sunday-Saturday)
  }, [])
  const updateCard = (comment: TTask) => {
    const newCard = { ...card };
    newCard.tasks.push(comment);
    setCard(newCard);
    const newBoard = { ...board };
    newBoard.cards[card.id] = newCard;
    updateBoard(newBoard);
    setDeadline(null);
    setTitle('');
  };

  function reset() {
    setDeadline(null);
    setTitle('');
    setFiles(null);
  }

  function handleClose() {
    props.onClose();
    reset();
  }
  const saveAddTask = useAPI('addTask');
  const saveAttachFile = useAPI('attachFile');

  async function handleAddtask() {
    try {
      if (card && deadline) {
        const task = await saveAddTask({
          title,
          board_card: card?.pk,
          deadline,
          board: card.board,
        });
        const promises = files?.map(async file => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('filename', file.name);
          const data: TFile = await saveAttachFile(formData, task.pk, 'tasks');
          data.file = baseUrl + data.file;
          task.attachments.push(data);
        });
        if (promises) {
          await Promise.all(promises);
        }
        updateCard(task);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle style={{marginBottom: 15}}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div>
          <TextareaAutosize
            style={{
              fontSize: '18px',
              minWidth: '100%',
              fontFamily: 'Roboto',
            }}
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder="Description"
            minRows={5}
          />
          <div style={{ margin: '5px 0 15px' }}>
            <InputLabel>Deadline</InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {time ? (
                <DateTimePicker
                  value={deadline}
                  onChange={newValue => {
                    setDeadline(newValue);
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              ) : (
                <DatePicker
                  value={deadline}
                  onChange={newValue => {
                    setDeadline(newValue);
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              )}
            </LocalizationProvider>
          </div>
          <Checkbox
            checked={time}
            onChange={e => setTime(e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <span>Set Time</span>
          <AddAttachments files={files} handleDelete={handleDelete} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
            }}
          >
            <Attach onChange={onChange} id={'task'} />
            <Button variant="outlined" onClick={handleAddtask}>
              Add Task
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
