import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoutes = (props: any) => {
  const location = useLocation();
  console.log(location);
  return props.auth ? (
    props.children
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
