import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {
  DialogContent,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TBoardCard } from '../card';
import {
  useBoardContext,
  useCardContext,
  useMembersContext,
} from '../../contexts';
import { TBoard } from '../board';
import { TColumn } from '../column';
import { TDialogProps } from './add-task-modal';
import api from '../../services/ApiService';
import { EditCardDialog } from '../edit-card-modal';
import { GetText } from './tasks';
import styled from 'styled-components';
import CardTabs from './card-tabs';

export type TCardDialogProps = TDialogProps & {
  card: TBoardCard | null;
};

function move(
  board: TBoard,
  source: string,
  destination: string,
  index: number,
  id: string
): TBoard {
  const start = board?.columns[source];
  const finish = board?.columns[destination];

  const startCardIds = [...start.cardIds];
  startCardIds.splice(index, 1);
  const newStart: TColumn = {
    ...start,
    cardIds: startCardIds,
  };

  const finisCardIds = [...finish.cardIds, id];
  const newFinish: TColumn = {
    ...finish,
    cardIds: finisCardIds,
  };

  const newState: TBoard = {
    ...board,
    columns: {
      ...board.columns,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    },
  };
  return newState;
}

export function CardDialog(props: TCardDialogProps) {
  const matches = useMediaQuery('(max-width:480px)');
  const { onClose, open } = props;

  const { card, setCard } = useCardContext();
  const { updateBoard, board } = useBoardContext();

  function removeCardFromBoard(id: string, column: number) {
    const newBoard = { ...board };
    delete newBoard.cards[id];
    newBoard.columns[`column-${column}`].cardIds = newBoard.columns[
      `column-${column}`
    ].cardIds.filter(cardId => cardId !== id);
    updateBoard(newBoard);
  }

  const [sel, setSel] = useState<number | undefined>();

  useEffect(() => {
    setSel(card?.status);
  }, [card]);

  async function handleChange(event: SelectChangeEvent) {
    const id = event.target.value;
    setSel(+id);
    if (card) {
      try {
        await api.changeStatus(card?.pk, +id);
        const newBoard = move(
          board,
          `column-${card.status}`,
          `column-${id}`,
          card.index,
          card.id
        );
        updateBoard(newBoard);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const { assigners } = useMembersContext();

  async function handleDelete() {
    if (card) {
      await api.removeCard(card.pk);
      removeCardFromBoard(card.id, card.status);
      onClose();
    }
  }

  const [edit, setEdit] = useState(false);
  const [assign, setAssign] = useState(false);

  async function handleAdd(assigned: number) {
    if (card) {
      try {
        const [newCard] = await api.assignUser({ assigned }, card?.pk);
        setCard(newCard);
        const newBoard = { ...board };
        newBoard.cards[card.id] = newCard;
        updateBoard(newBoard);
        setAssign(false);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const isAssigned = (id: number) => card?.assigned?.pk === id;

  if (!card) return null;

  return (
    <>
      <EditCardDialog open={edit} onClose={() => setEdit(false)} />
      <Dialog open={assign} onClose={() => setAssign(false)}>
        <DialogTitle>Assign responsible user</DialogTitle>
        <DialogContent>
          {Array.isArray(assigners) && assigners?.map((item) => {
            const isAss = isAssigned(item.pk);
            return (
              <Row onClick={() => !isAss && handleAdd(item.pk)} key={item.pk}>
                <span>{item.username}</span>
                {isAss && (
                  <IconButton onClick={handleDelete} color="primary">
                    <DeleteIcon />
                  </IconButton>
                )}
              </Row>
            );
          })}
        </DialogContent>
      </Dialog>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Wrapper>
            <p style={{ margin: '10px 0 0' }}>
              {card?.card?.full_name || card?.topic?.title}
            </p>
            <div>
              <IconButton onClick={() => setEdit(true)} color="primary">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => setAssign(true)} color="primary">
                <GroupAddIcon />
              </IconButton>
              <IconButton onClick={handleDelete} color="primary">
                <DeleteIcon />
              </IconButton>
            </div>
          </Wrapper>
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: !matches ? '600px' : '320px',
            minHeight: !matches ? '700px' : 0,
          }}
        >
          {card.assigned && (
            <div style={{ margin: '10px 0' }}>
              <InputLabel>Assigned</InputLabel>
              <span>{card.assigned.username}</span>
            </div>
          )}
          <div style={{ margin: '0 0 10px' }}>
            <InputLabel htmlFor="status">Status</InputLabel>
            <Select
              name="status"
              onChange={handleChange}
              value={sel + ''}
              sx={{ width: '100%' }}
            >
              {board.columnOrder.map(id => (
                <MenuItem key={id} value={+id.split('-')[1]}>
                  {board.columns[id].title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Divider />
          <div style={{ margin: '10px 0' }}>
            <InputLabel htmlFor="status">Description</InputLabel>
            <GetText text={card.description} maxLength={100} />
          </div>
          <CardTabs />
        </DialogContent>
      </Dialog>
    </>
  );
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
    & > div {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
