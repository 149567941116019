import styled from 'styled-components';

export const Box = styled.div`
  padding: 20px 20px 10px;
  border-radius: 10px;
  background-color: #fff;
  min-width: 300px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 480px) {
    height: auto;
  }
`;

export const Header = styled.h1`
  color: #29465b;
`;

export const LogoImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 130px;
  }
`;
