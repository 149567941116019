import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #3f3f3f;

  * {
    color: #fff;
    font-size: 18px;
  }
  min-height: 42px;
  padding: 0 20px;
  @media screen and (max-width: 1440px) {
    * {
      font-size: 14px;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const Button = styled.button`
  border: none;
  cursor: pointer;
  background: none;
`;
