import { Button, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/ApiService';
import { InputWrapper } from '../../ui/styles';
import { Box, Container, Header } from './styles';

export function Signup() {
  const navigate = useNavigate();
  const [err, setError] = useState('');

  async function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
      confirm: { value: string };
      email: { value: string };
      first_name: { value: string };
      last_name: { value: string };
    };
    if (target.password.value !== target.confirm.value) {
      setError("Passwords don't match");
      return;
    }
    const body = {
      username: target.username.value,
      password: target.password.value,
      password2: target.confirm.value,
      email: target.email.value,
      first_name: target.first_name.value,
      last_name: target.last_name.value,
    };
    try {
      await api.signUp(body);
      navigate('/end', { state: { email: body.email } });
    } catch (error: any) {
      if (error.json) {
        const body = await error.json();
        // setError(JSON.stringify(body));
        let errorString = '';
        for (const [key, value] of Object.entries(body)) {
          errorString += `${key}: ${(value as Array<string>)[0]}\n`;
        }
        setError(errorString);
      }
    }
  }

  return (
    <Container>
      <Header>Faraday CRM</Header>
      <form onSubmit={handleSubmit}>
        <Box>
          {err && (
            <p
              style={{
                color: 'red',
                maxWidth: '300px',
                whiteSpace: 'pre-line',
              }}
            >
              {err}
            </p>
          )}
          <InputWrapper>
            <InputLabel>username</InputLabel>
            <TextField name="username" required fullWidth />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>password</InputLabel>
            <TextField type="password" name="password" required fullWidth />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>confirm</InputLabel>
            <TextField type="password" name="confirm" required fullWidth />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>email</InputLabel>
            <TextField type="email" name="email" required fullWidth />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>first name</InputLabel>
            <TextField name="first_name" required fullWidth />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>last name</InputLabel>
            <TextField name="last_name" required fullWidth />
          </InputWrapper>
          <Button
            variant="outlined"
            type="submit"
            sx={{ marginTop: '15px' }}
            fullWidth
          >
            Signup
          </Button>
          <p>
            <Link to="/signin">Signin</Link>
          </p>
        </Box>
      </form>
    </Container>
  );
}
