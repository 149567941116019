import React, {useRef, useState} from 'react';
import {
    ButtonChat,
    InputRow,
    TextArea,
    TextAreaWrapper,
} from "./style";
import { Send } from "@mui/icons-material";

export const MessageTextEditorSimple = ({sendMessage, token, chatId}: any) => {
    const [text, setText] = useState<string>('');

    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setText(e.currentTarget.value);
    };

    const onFormSubmit = () => {
        if (text.trim()) {
            const messageObject = {
                chatId,
                token,
                text,
                createdAt: new Date(),
            };
            sendMessage(messageObject);
            setText('');
            if (inputRef.current) {
                inputRef.current.style.height = 'auto';
            }
        }
    };

    return (
        <>
            <InputRow>
                <TextAreaWrapper>
                    <TextArea
                      onInput={function (e) {
                          if (inputRef.current) {
                              inputRef.current.style.height = 'auto';
                              if (inputRef.current.value.length > 0) {
                                  inputRef.current.style.height =
                                    inputRef.current?.scrollHeight + 'px';
                              }
                          }
                      }}
                      value={text}
                      onChange={handleChange}
                      rows={1}
                      placeholder="Enter a message"
                      maxLength={10000}
                      ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                    />
                </TextAreaWrapper>
                <ButtonChat onClick={() => onFormSubmit()}>
                    <Send />
                </ButtonChat>

            </InputRow>
        </>
    );
}

export default MessageTextEditorSimple;
