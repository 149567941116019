import { Avatar, useTheme } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useCardContext } from '../../contexts';
import { TAssigner } from '../../hooks/useMembers';
import { hashCode, intToRGB } from '../../services/utils';
import { TTask } from '../task';
import { TAttachment } from '../../services/ApiService';

const Container = styled.div<{ isDragging: boolean }>`
  /* border: 1px solid black; */
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${(props) =>
    props.isDragging ? 'lightgreen' : 'rgb(204, 215, 227)'};
  @media (max-width: 1440px) {
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #123456;
  @media (max-width: 1440px) {
    font-size: 14px;
  }
`;

const TaskList = styled.div``;

const TagList = styled.div`
  color: rgb(25, 118, 210);
  span {
    color: #123456;
  }
`;

const Description = styled.div`
  color: #123456;
  max-height: 42px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type TOwner = {
  first_name: string;
  last_name: string;
};

export type TFile = {
  file: string;
  pk: number;
};

export type TComment = {
  pk: number;
  text: string;
  created_on: string;
  owner_info: TOwner;
  attachments: TFile[];
};


export type TInvoice = {
  pk: number;
  title: string;
  sum: number;
  done: boolean;
};


export type TTags = {
  pk: number;
  title: string;
};


export type TCard = {
  phones: { card: number; phone: string }[];
  emails: { card: number; email: string }[];
  course: string;
  card_type: 'TOPIC' | 'CLI' | 'STU' | 'DEV' | undefined;
  full_name: string;
  project_name: string;
  assigned: TAssigner;
  balance?: number;
  price?: number;
  age?: number;
};

export type TTopic = {
  title: string;
};

export type TFbLead = {
  fields: Array<{ name: string, value: string }>,
  pk: number;
}

export type TBoardCard = {
  pk: number;
  description: string;
  tasks: TTask[];
  status: number;
  creator: number;
  order: number;
  board: number;
  comments: TComment[];
  id: string;
  creator_info: TOwner;
  card?: TCard;
  topic?: TTopic;
  assigned: TAssigner;

  invoices: TInvoice[];
  tags: TTags[];

  fblead?: TFbLead;
};

export function Card({ card, index }: { card: TBoardCard; index: number }) {
  const { setCard } = useCardContext();
  const { breakpoints } = useTheme();

  function handleClick() {
    console.log(card);
    setCard({ ...card, index });
  }

  return (
    <Draggable draggableId={`card-${card.pk}`} index={index}>
      {(provided, snapshot) => (
        <Container
          onClick={handleClick}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>{card.card ? card.card.full_name : card.topic?.title}</Title>
            {card.assigned && (
              <Avatar
                sx={{
                  bgcolor: '#' + intToRGB(hashCode(card.assigned.username)),
                  // [breakpoints.up(1440)]: {
                  //   width: '10px',
                  //   height: '10px',
                  // },
                  width: '20px',
                  height: '20px',
                  fontSize: 14,
                }}
              >
                {card.assigned.username.slice(0, 1)}
              </Avatar>
            )}
          </div>
          <Description>{card.description}</Description>
          {card.tasks.length > 0 && (
            <TaskList>
              <span>Deadlines:</span>
              {card.tasks.filter((task) => !task.done).length !== 0 ? (
                card.tasks
                  .filter((task) => !task.done)
                  .map((task) => (
                    // <Task key={task.id} task={task} />
                    <div key={task.pk}>
                      {new Date(task.deadline).toDateString()}
                    </div>
                  ))
              ) : (
                <span style={{ color: 'green' }}> All tasks completed</span>
              )}
            </TaskList>
          )}
          {card.assigned && (
            <Description>Assigned: {card.assigned.username}</Description>
          )}
          {card.card && (
            <>
              <div>
                phone: {card.card?.phones && card.card?.phones[0]?.phone}
              </div>
              {card.card.card_type === 'STU' && (
                <Description>course: {card.card?.course || '-'}</Description>
              )}
              <Description>
                admin:{' '}
                {card.creator_info.first_name +
                  ' ' +
                  card.creator_info.last_name}
              </Description>
            </>
          )}
          {card.tags.length > 0 && (
            <TagList>
              <span>Tags:</span>
              {card.tags
                .map((tags) => ` ${tags.title}`)}
            </TagList>
          )}
          {card.fblead && (
            <div style={{
              marginTop: '5px',
              display: 'flex',
            }}>
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="18px" height="18px">
                <path d="M17,3H7C4.791,3,3,4.791,3,7v10c0,2.209,1.791,4,4,4h5.621v-6.961h-2.343v-2.725h2.343V9.309 c0-2.324,1.421-3.591,3.495-3.591c0.699-0.002,1.397,0.034,2.092,0.105v2.43h-1.428c-1.13,0-1.35,0.534-1.35,1.322v1.735h2.7 l-0.351,2.725h-2.365V21H17c2.209,0,4-1.791,4-4V7C21,4.791,19.209,3,17,3z"/>
              </svg>
            </div>
          )}
        </Container>
      )}
    </Draggable>
  );
}
