import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts';
import { Button, Container, Info, User } from './styles';

export function Header() {
  const { onLogout, profile } = useAuthContext();

  return (
    <Container>
      <Info>
        <User>
          <p>{profile.email}</p>|
          <p>{profile.first_name + ' ' + profile.last_name}</p>@
          <p>{profile.username}</p>
        </User>
      </Info>
      <Info>
        <Link to="/" style={{ textDecoration: 'none', marginRight: '10px' }}>
          My Boards
        </Link>
        <Button onClick={onLogout}>Logout</Button>
      </Info>
    </Container>
  );
}
