import { SyntheticEvent, useEffect, useState } from 'react';
import { TMember, TSelectMember } from '../components/edit-board-modal';
import { TBoardData } from '../pages/board';
import api from '../services/ApiService';

export default function useMember(board: TBoardData) {
  const [members, setMembers] = useState<TMember[]>([]);

  useEffect(() => {
    if (board) {
      (async function () {
        try {
          const data = await api.getSharedMembers(board.pk);
          setMembers(data);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [board]);

  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
      type: { value: string };
    };

    setAddMembers(old => [
      ...old,
      {
        email: target.email.value,
        share_type: target.type.value,
      },
    ]);
    (e.target as any).reset();
  }

  const [rmMembers, setRmMembers] = useState<number[]>([]);
  const [addMembers, setAddMembers] = useState<
    {
      email: string;
      share_type: string;
    }[]
  >([]);
  const [editMembers, setEditMembers] = useState<TSelectMember[]>([]);

  function onRemove(id: number) {
    setRmMembers(old => [...old, id]);
    setMembers(old => old.filter(member => member.pk !== id));
  }

  function onEdit(member: TSelectMember) {
    setEditMembers(old => [...old, member]);
  }

  return {
    members,
    rmMembers,
    addMembers,
    editMembers,
    onRemove,
    onEdit,
    handleSubmit,
  };
}
