import React, { useEffect, useMemo, useRef } from 'react';
import {
    MessageRight,
    MessageLeft,
    MessagesBlock,
    MessagesBlockDay,
    DateMessageRight,
    DateMessageLeft,
    FileBlock
} from "./style";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Linkify from "react-linkify";
import {SecureLink} from "react-secure-link";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../stores";

export const ChatBlock = observer(() => {
    const {
        chatStore: {
            messages
        }
    } = useStore();
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }

    const getTime = (date: Date) => {
        const today = new Date();
        const dateFormat = new Date(date);

        if (
            today.getFullYear() === dateFormat.getFullYear() &&
            today.getMonth() === dateFormat.getMonth() &&
            today.getDate() === dateFormat.getDate()
        ) {
            return String(dateFormat.getHours()).padStart(2, '0') + ':' + String(dateFormat.getMinutes()).padStart(2, '0');
        }
        return dateFormat.toLocaleDateString('uk-UA');
    }

    useEffect(() => {
        console.log('messages');
    }, [messages]);

    const messagesRender = useMemo(() => {
        if (messages) {
            const result = messages.map((el) => el?.user_id ? (
                <React.Fragment key={el.id}>
                    <MessageRight
                        onClick={() => (el.file && !el.file.name.includes('blob')) ? window.open(`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`,'_blank') : null}
                    >
                        {!el.text && el.file ? (
                            <FileBlock>
                                {el.file.name.includes('blob') ? (
                                    <audio controls src={`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`} />
                                ) : (
                                    <>
                                        <InsertDriveFileIcon />
                                        {el.file.name}
                                    </>
                                )}
                            </FileBlock>
                        ) : (
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                            )}>
                                {el.text}
                            </Linkify>
                        )}
                    </MessageRight>
                    {el.createdAt && (
                      <DateMessageRight>{getTime(el.createdAt)}</DateMessageRight>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment key={el.id}>
                    <MessageLeft
                        onClick={() => (el.file && !el.file.name.includes('blob')) ? window.open(`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`,'_blank') : null}
                    >
                        {!el.text && el.file ? (
                            <FileBlock>
                                {el.file.name.includes('blob') ? (
                                    <audio controls src={`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`} />
                                ) : (
                                    <>
                                        <InsertDriveFileIcon />
                                        {el.file.name}
                                    </>
                                )}
                            </FileBlock>
                        ) : (
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                            )}>
                                {el.text}
                            </Linkify>
                        )}
                    </MessageLeft>
                    {el.createdAt && (
                      <DateMessageLeft>{getTime(el.createdAt)}</DateMessageLeft>
                    )}
                </React.Fragment>
            ))
            setTimeout(() => {
                scrollToBottom();
            }, 0)
            return result;
        }
    }, [messages]);

    return (
        <>
            <MessagesBlock ref={messagesEndRef}>
                <MessagesBlockDay>
                    {messagesRender}
                </MessagesBlockDay>
            </MessagesBlock>
        </>
    );
});
export default ChatBlock;