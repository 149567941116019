import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel, IconButton,
  TextareaAutosize,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { SyntheticEvent, useState } from 'react';
import { useUpdateCard } from '../../hooks';
import useAPI from '../../hooks/useAPI';
import { InputWrapper } from '../../ui/styles';
import { TBoardCard } from '../card';
import { TTask } from '../task';
import CloseIcon from '@mui/icons-material/Close';

type TProps = { task: TTask; onClose: () => void };

export default function EditTask({ task, onClose }: TProps) {
  const [deadline, setDeadline] = useState<string | null>(task.deadline);
  const editTaskSafe = useAPI('editTask');

  function updateCard(
    card: TBoardCard,
    data: { title: string; deadline: string }
  ) {
    const taskToUpdate = card.tasks.find(item => item.pk === task.pk);
    if (taskToUpdate) {
      task.title = data.title;
      task.deadline = data.deadline;
    }
    return card;
  }

  const update = useUpdateCard(updateCard);

  async function handleSubmit(e: SyntheticEvent) {
    if (!deadline) return; // add ui/ux
    e.preventDefault();
    const target = e.target as typeof e.target & {
      title: { value: string };
    };
    try {
      const [data] = await editTaskSafe(task.pk, {
        title: target.title.value,
        deadline,
      });
      update({ ...data });
      onClose();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 10,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <p style={{ margin: '0 25px' }}>
          Edit Task
        </p>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit} id="task-form">
          <TextareaAutosize
            style={{
              fontSize: '18px',
              minWidth: '100%',
              fontFamily: 'Roboto',
            }}
            defaultValue={task.title}
            placeholder="Description"
            minRows={5}
            name="title"
          />
          <InputWrapper>
            <FormLabel>Deadline</FormLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                value={deadline}
                onChange={newValue => {
                  setDeadline(newValue);
                }}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
          </InputWrapper>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="task-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
