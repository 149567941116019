import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { useUpdateCard } from '../../hooks';
import api from '../../services/ApiService';
import { TBoardCard } from '../card';

export function TaskCheckbox(props: { checked: boolean; id: number }) {
  const [checked, setChecked] = useState(props.checked);

  function updateCard(card: TBoardCard, checked: boolean) {
    const task = card.tasks.find(task => task.pk === props.id);
    if (task) {
      task.done = checked;
    }
    return card;
  }

  const update = useUpdateCard(updateCard);

  async function handleTask(event: React.ChangeEvent<HTMLInputElement>) {
    const done = event.target.checked;
    setChecked(done);
    try {
      await api.editTask(props.id, { done });
      update(done);
    } catch (error) {
      console.log(error);
    }
  }
  return <Checkbox checked={checked} onChange={handleTask} />;
}
