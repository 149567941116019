import styled from 'styled-components';

export const CommentForm = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-end;
`;

export const CommentList = styled.div`
  margin-top: 10px;
  max-height: 200px;
  overflow: auto;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

export const TaskList = styled.div`
  max-height: 500px;
  overflow: auto;
`;

export const Comment = styled.div`
  padding: 10px 0;
`;

export const TaskHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

export const Img = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const InputWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  @media (max-width: 480px) {
    margin-bottom: 5px;
  }
`;

export const AddWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Files = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  /* border: 1px solid rgba(25, 118, 210, 0.5); */
  padding: 0 5px;
`;

export const FileWrapper = styled.div`
  margin-left: 10px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledLink = styled.a`
  color: inherit;
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 100%;
`;

export const Right = styled.div`
  display: flex;
  padding: 5px 0;
  align-items: center;
`;
