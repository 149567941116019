import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
    & > div {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

