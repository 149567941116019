import { DropResult } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { TBoard } from '../components/board';
import { TColumn } from '../components/column';
import api from '../services/ApiService';

export type TBoardState = {
  board?: TBoard;
  updateBoard: (newBoard: TBoard) => void;
};

export default function useDrag({ board, updateBoard }: TBoardState) {
  async function onDragEnd(result: DropResult) {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    // move column
    if (type === 'column' && board) {
      if (destination.index < 2) {
        return;
      }
      try {
        await api.moveColumn(+draggableId.split('-')[1], destination.index);
      } catch (error: any) {
        console.log(error);
        if (error?.status === 403) {
          toast.error('Forbidden');
        }
        return;
      }
      const newColumnOrder = [...board?.columnOrder];
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState: TBoard = {
        ...board,
        columnOrder: newColumnOrder,
      };

      updateBoard(newState);
      return;
    }
    //

    const start = board?.columns[source.droppableId];
    const finish = board?.columns[destination.droppableId];
    // move card in column
    if (start && finish) {
      try {
        await api.moveCard(+draggableId.split('-')[1], destination.index);
      } catch (error: any) {
        console.log(error);
        if (error?.status === 403) {
          toast.error('Forbidden');
        }
        return;
      }
      if (start === finish) {
        const newCardIds = [...start.cardIds];
        newCardIds.splice(source.index, 1);
        newCardIds.splice(destination.index, 0, draggableId);

        const newColumn: TColumn = {
          ...start,
          cardIds: newCardIds,
        };

        const newState: TBoard = {
          ...board,
          columns: {
            ...board.columns,
            [newColumn.id]: newColumn,
          },
        };

        updateBoard(newState);
        return;
      }
      //
      // move card between columns
      try {
        await api.moveCard(
          +draggableId.split('-')[1],
          destination.index,
          +destination.droppableId.split('-')[1]
        );
      } catch (error: any) {
        console.log(error);
        if (error?.status === 403) {
          toast.error('Forbidden');
        }
        return;
      }
      const startCardIds = [...start.cardIds];
      startCardIds.splice(source.index, 1);
      const newStart: TColumn = {
        ...start,
        cardIds: startCardIds,
      };

      const finisCardIds = [...finish.cardIds];
      finisCardIds.splice(destination.index, 0, draggableId);
      const newFinish: TColumn = {
        ...finish,
        cardIds: finisCardIds,
      };

      const newState: TBoard = {
        ...board,
        columns: {
          ...board.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };
      newState.cards[draggableId].status = newFinish.pk;
      updateBoard(newState);
      //
    }
  }

  return onDragEnd;
}
