import {makeAutoObservable, runInAction} from 'mobx';
import { ApiServiceChat } from '../ApiService';

type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}

type RoomT = {
    id: number,
    facebook_user: string,
    facebook_user_name: string,
    user_id: number,
}

export default class RoomStore {
    isLoading = true;
    rooms: RoomT[] = [];
    selectRoom?: RoomT | null;
    error?: ErrorT;

    lastAbortController: any;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setSelected(id: number | null) {
        let selected = null;
        if (id) {
            selected = this.rooms?.find((el) => el.id === id) || null;
        }
        this.selectRoom = selected;
    }

    setError(error: ErrorT) {
        this.error = error;
    }

    getRooms(token: string) {
        this.isLoading = true;

        if (this.lastAbortController) {
            this.lastAbortController.abort();
        }

        const abortController = new AbortController();
        this.lastAbortController = abortController;

        ApiServiceChat({
            token,
            endPoint: 'chat/get-chats',
            method: 'GET',
            headers: {'application': localStorage.getItem('appId')},
            signal: abortController.signal,
        }).then((res) => {
            if (abortController.signal.aborted) {
                return new Promise(() => undefined);
            }
            runInAction(() => {
                this.rooms = res;
                this.isLoading = false;
            });
        });
    }
}
