import React, { ChangeEvent, useState } from 'react';
import { Button, Dialog, DialogContent, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import TextField from '@mui/material/TextField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { TextareaAutosize } from '@mui/material';
import { TDCard, useBoardContext, useCardContext } from '../../contexts';
import { AddWrapper } from './styles';
import useAPI from '../../hooks/useAPI';
import { TInvoice } from '../card';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

const pptMIMEtypes =
  'application/mspowerpoint,application/powerpoint,application/vnd.ms-powerpoint,application/x-mspowerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.ppt,.pptx';
const MIMEtypes =
  '.doc,.docx,application/msword,application/ms-doc,application/doc,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/*,.pdf';
const acceptList = `${pptMIMEtypes},${MIMEtypes}`;

export const Attach = ({
  onChange,
  id,
}: {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
}) => (
  <AddWrapper>
    <label htmlFor={id}>
      <AttachFileIcon color="primary" />
    </label>
    <input
      type="file"
      accept={acceptList}
      onChange={onChange}
      style={{ display: 'none' }}
      id={id}
      multiple
    />
  </AddWrapper>
);

export type TDialogProps = {
  open: boolean;
  onClose: () => void;
};

type TTaskDialogProps = TDialogProps & { card: TDCard };

export function AddInvoiceDialog(props: TTaskDialogProps) {
  const { card } = props;
  const [title, setTitle] = useState('');
  const [lang, setLang] = useState<'en' | 'uk'>('uk');
  const [sum, setSum] = useState<number | undefined>();

  const { setCard } = useCardContext();
  const { updateBoard, board } = useBoardContext();

  const updateCard = (invoice: TInvoice) => {
    const newCard = { ...card };
    newCard.invoices.push(invoice);
    setCard(newCard);
    const newBoard = { ...board };
    newBoard.cards[card.id] = newCard;
    updateBoard(newBoard);
    setTitle('');
  };

  function reset() {
    setTitle('');
  }

  function handleClose() {
    props.onClose();
    reset();
  }

  const saveInvoices = useAPI('addInvoice');

  async function handleAddInvoice() {
    try {
      if (card) {
        const invoice = await saveInvoices({
          title,
          sum,
          lang,
          board_card: card?.pk,
          board: card.board,
        });

        updateCard(invoice);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle style={{marginBottom: 15}}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <TextareaAutosize
            style={{
              fontSize: '18px',
              minWidth: '100%',
              fontFamily: 'Roboto',
            }}
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder="Description"
            minRows={5}
          />
          <div style={{ margin: '5px 0 15px' }}>
            <InputLabel>Sum</InputLabel>
            <TextField
              type="number"
              value={sum}
              onChange={e => setSum(Number(e.target.value))}
              placeholder="Sum"
            />
          </div>
          <div style={{ margin: '5px 0 15px' }}>
            <InputLabel>Language</InputLabel>
            <Select
              name="lang"
              onChange={(e: SelectChangeEvent<'en' | 'uk'>) => {
                setLang(e.target.value as 'en' | 'uk');
              }}
              value={lang}
              style={{width: '100%'}}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="uk">Ukrainian</MenuItem>
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
            }}
          >
            <Button variant="outlined" onClick={handleAddInvoice}>
              Add Invoice
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
