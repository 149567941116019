import { useState } from 'react';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { TaskList, TaskHeader, Left, Right } from './styles';
import { AddInvoiceDialog } from './add-invoice-modal';
import { useBoardContext, useCardContext } from '../../contexts';
import useAPI from '../../hooks/useAPI';
import EditInvoice from './edit-invoice';
import { GetText } from '../card-modal/tasks';
import { TInvoice } from '../card';


export default function Invoices() {
  const { card, setCard } = useCardContext();

  const [addInvoiceOpen, setOpen] = useState(false);

  const { updateBoard, board } = useBoardContext();

  function removeInvoiceFromCard(id: number) {
    if (card) {
      const updatedBoard = { ...board };
      const updatedCard = board.cards[card.id];
      const invoices = updatedCard.invoices.filter((invoice) => invoice.pk !== id);
      updatedCard.invoices = invoices;
      const dialogCard = { ...card };
      dialogCard.invoices = invoices;
      setCard(dialogCard);
      updateBoard(updatedBoard);
    }
  }

  const handleAddInvoice = () => setOpen(true);
  const [show, setShow] = useState(0);
  const handleClose = () => setShow(0);
  const deleteInvoice = useAPI('deleteInvoice');
  async function handleDelete() {
    try {
      await deleteInvoice(show);
      removeInvoiceFromCard(show);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  }

  const [edit, setEdit] = useState<TInvoice | null>(null);

  const doneInvoiceAction = useAPI('doneInvoice');

  const doneInvoice = async (id: number) => {
    try {
      if (card) {
        const { balance } = await doneInvoiceAction(id);

        const updatedBoard = { ...board };
        const updatedCard = board.cards[card.id];
        const invoices = updatedCard.invoices.filter((invoice) => invoice.pk !== id);
        updatedCard.invoices = invoices;

        if (updatedCard.card) updatedCard.card.balance = balance;

        const dialogCard = { ...card };
        dialogCard.invoices = invoices;


        setCard(dialogCard);
        updateBoard(updatedBoard);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {edit && <EditInvoice invoice={edit} onClose={() => setEdit(null)} />}
      {!!show && (
        <Dialog onClose={() => setShow(0)} open={!!show}>
          <DialogTitle sx={{ width: '300px' }}>Delete Invoice ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {card && (
        <AddInvoiceDialog
          open={addInvoiceOpen}
          onClose={() => setOpen(false)}
          card={card}
        />
      )}
      <TaskHeader>
        <InputLabel>Invoices</InputLabel>
        <Button variant="outlined" onClick={handleAddInvoice}>
          Add Invoice
        </Button>
      </TaskHeader>
      <TaskList>
        {card?.invoices?.map((invoice) => (
          <div
            key={invoice.pk}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Left>
              <ListItemText
                primary={<GetText text={invoice.title} />}
                sx={{ textDecoration: invoice.done ? 'line-through' : 'none' }}
              />
              <ListItemText
                secondary={<GetText text={String(invoice.sum)} />}
                sx={{ marginLeft: '5px', flex: '1 0 auto' }}
              />
            </Left>
            <Right>
              <IconButton onClick={() => doneInvoice(invoice.pk)}>
                <CheckIcon />
              </IconButton>
              <IconButton onClick={() => setShow(invoice.pk)}>
                <CloseIcon />
              </IconButton>
            </Right>
          </div>
        ))}
      </TaskList>
    </>
  );
}
