import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  DialogContent, IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { TBoardCard } from '../card';
import { FormLabel, TextareaAutosize } from '@mui/material';
import { FormContainer, Wrapper } from './styles';
import { useBoardContext } from '../../contexts';
import api, { TCardBody, TTopicBody } from '../../services/ApiService';

import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';
import { InputWrapper } from '../../ui/styles';
import CloseIcon from '@mui/icons-material/Close';

export function NewCardDialog(props: {
  open: number;
  owner: number;
  onClose: (open: number) => void;
}) {
  const { onClose, open } = props;
  const matches = useMediaQuery('(max-width:480px)');

  const { board, updateBoard } = useBoardContext();

  function addCardToBoard(card: TBoardCard) {
    const id = `card-${card.pk}`;
    card.id = id;
    card.tasks = [];
    card.comments = [];
    const newBoard = { ...board };
    newBoard.cards[id] = card;
    newBoard.columns[`column-${card.status}`].cardIds.push(id);
    updateBoard(newBoard);
  }

  async function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    let body: TCardBody | TTopicBody;

    if (type === 'TOPIC') {
      const target = e.target as typeof e.target & {
        title: { value: string };
        description: { value: string };
      };
      body = {
        board: board.pk,
        status: open,
        creator: props.owner,
        description: target.description.value,
        type: 'TOPIC',
        topic_fields: {
          title: target.title.value,
        },
      };
    } else {
      const target = e.target as typeof e.target & {
        type: { value: string };
        full_name: { value: string };
        project_name: { value: string };
        course: { value: string };
        description: { value: string };
        phone: { value: string };
        age: { value: string };
        email: { value: string };
        price: { value: number };
        balance: { value: number };
        tags_add: { value: string }
      };
      console.log(target.age.value)
      body = {
        tags_add: target.tags_add.value,
        board: board.pk,
        status: open,
        creator: props.owner,
        type: 'CARD',
        description: target.description.value,
        card_fields: {
          card_type: target.type.value,
          full_name: target.full_name.value,
          project_name: target.project_name.value,
          ...(type === 'STU' || type === 'CLI' ? { course: target.course.value } : {}),
          phone: target.phone.value,
          email: target.email.value,
          age: target.age.value,
          balance: target.balance.value,
          price: target.price.value,
        },
      };
    }

    try {
      const card = await api.addCard(body);
      addCardToBoard(card);
      onClose(0);
    } catch (error: any) {
      console.log(error);
      if (error?.status === 403) {
        toast.error('Forbidden');
      }
    }
  }

  const [type, setType] = useState<'TOPIC' | 'CLI' | 'STU' | 'DEV'>('TOPIC');

  function handleChange(e: SelectChangeEvent<'TOPIC' | 'CLI' | 'STU' | 'DEV'>) {
    setType(e.target.value as 'TOPIC' | 'CLI' | 'STU' | 'DEV');
  }

  return (
    <Dialog onClose={onClose} open={!!open}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose(open);
          }}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Wrapper>
          <p style={{ margin: '10px 0 0' }}>
            Add New Card
          </p>
        </Wrapper>
      </DialogTitle>
      <DialogContent sx={{ minWidth: !matches ? '350px' : '300px' }}>
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <InputWrapper>
              <FormLabel>Type</FormLabel>
              <Select name="type" onChange={handleChange} value={type}>
                <MenuItem value="CLI">Client</MenuItem>
                <MenuItem value="STU">Student</MenuItem>
                <MenuItem value="DEV">Developer</MenuItem>
                <MenuItem value="TOPIC">Topic</MenuItem>
              </Select>
            </InputWrapper>
            {type === 'TOPIC' && (
              <InputWrapper>
                <FormLabel>Title</FormLabel>
                <TextField name="title" variant="outlined" required />
              </InputWrapper>
            )}
            <InputWrapper>
              <FormLabel>Description</FormLabel>
              <TextareaAutosize
                style={{
                  fontSize: '18px',
                  minWidth: '100%',
                  fontFamily: 'Roboto',
                }}
                aria-label="empty textarea"
                placeholder="Empty"
                name="description"
                minRows={5}
              />
            </InputWrapper>
            {type !== 'TOPIC' && (
              <>
                <InputWrapper>
                  <FormLabel>Full Name</FormLabel>
                  <TextField name="full_name" variant="outlined" />
                </InputWrapper>
                <InputWrapper>
                  <FormLabel>Project Name</FormLabel>
                  <TextField name="project_name" variant="outlined" required />
                </InputWrapper>
                <FormLabel>Phone</FormLabel>
                <TextField name="phone" variant="outlined" required />
                <FormLabel>Email</FormLabel>
                <TextField
                  name="email"
                  type="email"
                  variant="outlined"
                  required
                />
                <FormLabel>Balance</FormLabel>
                <TextField
                  name="balance"
                  type="text"
                  variant="outlined"
                  required
                />
                <FormLabel>Price</FormLabel>
                <TextField
                  name="price"
                  type="text"
                  variant="outlined"
                  required
                />
                {(type === 'STU' || type === 'CLI') && (
                  <>
                    <FormLabel>Age</FormLabel>
                    <TextField
                      name="age"
                      type="date"
                      variant="outlined"
                      required
                    />
                    <FormLabel>Course</FormLabel>
                    <TextField
                      name="course"
                      type="text"
                      variant="outlined"
                      required
                    />
                  </>
                )}
                {type === 'DEV' && (
                  <>
                    <FormLabel>Telegram</FormLabel>
                    <TextField
                      name="telegram"
                      type="text"
                      variant="outlined"
                      required
                    />
                    <FormLabel>Slack</FormLabel>
                    <TextField
                      name="slack"
                      type="text"
                      variant="outlined"
                      required
                    />
                    <FormLabel>Github</FormLabel>
                    <TextField
                      name="github"
                      type="text"
                      variant="outlined"
                      required
                    />
                  </>
                )}
              </>
            )}

            {(type === 'STU' || type === 'CLI') && (
              <>
                <FormLabel>Tags (separated by commas)</FormLabel>
                <TextField
                  name="tags_add"
                  type="text"
                  variant="outlined"
                />
              </>
            )}
          </FormContainer>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              sx={{ marginTop: '15px' }}
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
