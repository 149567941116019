import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IconButton, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../services/ApiService';
import useAPI from '../hooks/useAPI';

import { Header } from '../components/header/header';
import CreateBoardDialog, { TMember } from '../components/create-board-modal';

import {
  BoardContainer,
  BoardHeader,
  Btn,
  Container,
  H2,
  ListContainer,
  Text,
  Title,
} from './styles';
import { TBoardData } from './board';
import EditBoardDialog from '../components/edit-board-modal';
import { BoardsContext } from '../contexts';

type SharedBoard = {
  board: TBoardData;
  share_type: string;
  owner: number;
  pk: number;
};

export default function BoardListPage() {
  const [boards, setBoards] = useState<TBoardData[]>([]);
  const [sharedBoards, setSharedBoards] = useState<SharedBoard[]>([]);
  const [open, setOpen] = useState(false);
  const getBoardsSafe = useAPI('getBoards');
  const getSharedBoardsSafe = useAPI('getSharedBoards');
  const shareBoardSafe = useAPI('shareBoard');

  async function getBoards() {
    const data = await getBoardsSafe();
    setBoards(data.results);
    const dataShared = await getSharedBoardsSafe();
    setSharedBoards(dataShared.results);
  }

  useEffect(() => {
    getBoards();
  }, []);

  async function onAdd(name: string, members: TMember[]) {
    const dataBoard: TBoardData = await api.createBoard({
      name,
      owner: 3,
    });
    const promises = members.map(async member => {
      await shareBoardSafe({
        boardId: dataBoard.pk,
        email: member.email,
        share_type: member.type,
      });
    });
    await Promise.all(promises);
    setOpen(false);
    getBoards();
  }

  function handleCopy(copyText: string) {
    navigator.clipboard.writeText(copyText).then(() => {
      // Alert the user that the action took place.
      // Nobody likes hidden stuff being done under the hood!
      toast('Linked Copied');
    });
  }

  async function handleDelete(code: string) {
    try {
      await api.deleteBoard(code);
      setBoards(oldBoards => oldBoards.filter(board => board.code !== code));
    } catch (error) {
      console.log(error);
    }
  }

  async function handleShareDelete(id: number) {
    try {
      await api.deleteShare(id);
      setSharedBoards(oldBoards => oldBoards.filter(share => share.pk !== id));
    } catch (error) {
      console.log(error);
    }
  }

  const [editBoard, setEditBoard] = useState<TBoardData | null>(null);

  if (boards.length === 0 && sharedBoards.length === 0)
    return (
      <div>
        <CreateBoardDialog
          open={open}
          handleClose={() => setOpen(false)}
          handleAdd={onAdd}
        />
        <p style={{ padding: '10px' }}>
          Your board list is Empty. Create new one
        </p>
        <Button onClick={() => setOpen(true)}>Create New Bard</Button>
      </div>
    );

  return (
    <BoardsContext.Provider
      value={{
        boards,
        updateBoards: (boards: TBoardData[]) => setBoards(boards),
      }}
    >
      <Container>
        <CreateBoardDialog
          open={open}
          handleClose={() => setOpen(false)}
          handleAdd={onAdd}
        />
        {editBoard && (
          <EditBoardDialog
            board={editBoard}
            handleClose={() => setEditBoard(null)}
          />
        )}
        <Header />
        <Btn onClick={() => setOpen(true)}>
          <Add />
          <span>Create New</span>
        </Btn>
        <H2>My Boards</H2>
        <ListContainer>
          {boards.map(board => (
            <BoardContainer key={board.pk} to={`/board/${board.code}`}>
              <BoardHeader>
                <Title>Board name: {board.name}</Title>
                <div>
                  <IconButton
                    onClick={e => {
                      e.preventDefault();
                      setEditBoard(board);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={e => {
                      e.preventDefault();
                      handleDelete(board.code);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </BoardHeader>
              <Text>
                link:{' '}
                <Link
                  to={`/board/${board.code}`}
                  style={{ textDecoration: 'none' }}
                >
                  {board.code}
                </Link>
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    handleCopy(`${window.location}board/${board.code}`);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Text>
            </BoardContainer>
          ))}
        </ListContainer>
        <H2>Shared with Me</H2>
        <ListContainer>
          {sharedBoards.map(share => (
            <BoardContainer
              key={share.board.pk}
              to={`/board/${share.board.code}`}
            >
              <BoardHeader>
                <Title>Board name: {share.board.name}</Title>
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    handleShareDelete(share.pk);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </BoardHeader>
              <Text>
                code:{' '}
                <Link
                  to={`/board/${share.board.code}`}
                  style={{ textDecoration: 'none' }}
                >
                  {share.board.code}
                </Link>
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    handleCopy(`${window.location}board/${share.board.code}`);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Text>
            </BoardContainer>
          ))}
        </ListContainer>
      </Container>
    </BoardsContext.Provider>
  );
}
