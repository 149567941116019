import React, { useEffect } from 'react';
import {
    Box,
    Grid,
} from '@mui/material';
import MessageTextEditorSimple from "./Components/MessageTextEditorSimple/MessageTextEditorSimple";
import ChatBlock from "./Components/ChatBlock/ChatBlock";
import { WelcomeText, СhatArea } from "./style";
import RoomList from "./Components/RoomList/RoomList";
import { useSocket } from "../../hooks/useSocket";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores";
import { Header } from '../../components/header/header';

const boardId = '';

export const Chat = observer(() => {
    const { token, id } = useParams();

    const {
        chatStore: {
            getChat,
            clearMessages,
        },
        roomState: {
            selectRoom,
        }
    } = useStore();

    const {
        sendMessage,
        joinRoom,
        leaveRoom,
    } = useSocket();

    useEffect(() => {
        return () => {
            clearMessages();
        }
    }, []);

    useEffect(() => {
        if (id && token && selectRoom) {
            getChat(id, selectRoom.facebook_user, token);
        }
    }, [id, selectRoom]);

    useEffect(() => {
        console.log(id);
        if (Number(id)) {
            joinRoom({
                chatId: Number(id),
            });
        }
        return () => {
            leaveRoom({
                chatId: Number(id)
            });
        }
    }, [id]);

    return (
      <Box sx={{ display: 'flex' }}>
          <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
          >
              <Header />
              <Grid container sx={{
                  width: '100%',
                  height: 'calc(100% - 96px)',
                  boxShadow: 'none !important',
                  marginTop: '10px',
              }}>
                  {token && (
                    <RoomList id={id} token={token} />
                  )}
                  <Grid item xs={9}>
                      <СhatArea>
                          {id ? (
                            <>
                                <ChatBlock />
                                <Grid container style={{padding: '10px 0'}}>
                                    <MessageTextEditorSimple
                                      sendMessage={sendMessage}
                                      token={token}
                                      chatId={Number(id)}
                                    />
                                </Grid>
                            </>
                          ) : (
                            <WelcomeText>
                                Choose a chat to communicate
                            </WelcomeText>
                          )}
                      </СhatArea>
                  </Grid>
              </Grid>
          </Box>
      </Box>
    );
});

export default Chat;
