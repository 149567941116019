import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent, IconButton
} from '@mui/material';
import { TextareaAutosize } from '@mui/material';
import { TDCard, useBoardContext, useCardContext } from '../../contexts';
import { TTags } from '../card';
import useAPI from '../../hooks/useAPI';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';


export type TDialogProps = {
  open: boolean;
  onClose: () => void;
};

type TTagsDialogProps = TDialogProps & { card: TDCard };

export function AddTagsDialog(props: TTagsDialogProps) {
  const { card } = props;
  const [title, setTitle] = useState('');


  const { setCard } = useCardContext();
  const { updateBoard, board } = useBoardContext();

  const updateCard = (comment: TTags) => {
    const newCard = { ...card };
    newCard.tags.push(comment);
    setCard(newCard);
    const newBoard = { ...board };
    newBoard.cards[card.id] = newCard;
    updateBoard(newBoard);
    setTitle('');
  };

  function reset() {
    setTitle('');
  }

  function handleClose() {
    props.onClose();
    reset();
  }
  const saveAddTags = useAPI('addTags');

  async function handleAddTags() {
    try {
      if (card) {
        const tags = await saveAddTags({
          title,
          board_card: card?.pk,
          board: card.board,
        });
        updateCard(tags);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle style={{marginBottom: 15}}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <TextareaAutosize
            style={{
              fontSize: '18px',
              minWidth: '100%',
              fontFamily: 'Roboto',
            }}
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder="Description"
            minRows={5}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
            }}
          >
            <Button variant="outlined" onClick={handleAddTags}>
              Add Tag
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
