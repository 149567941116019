import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Files, FileWrapper, Img } from './styles';

const re = /^.*\.(jpg|JPG|doc|docx|DOC|pdf|PDF|png|jpeg|ppt|pptx)$/;

export function getType(file: File) {
  const ext = file.name.match(re);
  if (ext) {
    switch (ext[1]) {
      case 'ppt':
      case 'pptx':
        return <SlideshowIcon fontSize="large" />;
      case 'doc':
      case 'docx':
        return <ArticleIcon fontSize="large" />;
      case 'pdf':
        return <PictureAsPdfIcon fontSize="large" />;
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <Img src={URL.createObjectURL(file)} />;
    }
  }
  return null;
}

type TProps = {
  files: File[] | null;
  handleDelete: (idx: number) => void;
};

export default function AddAttachments({ files, handleDelete }: TProps) {
  return (
    <>
      {files && (
        <Files>
          {files.map((file, index) => (
            <FileWrapper key={`file-${index}-${file.name}`}>
              {getType(file)}
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                }}
                onClick={() => handleDelete(index)}
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            </FileWrapper>
          ))}
        </Files>
      )}
    </>
  );
}
