import styled from 'styled-components';

export const Result = styled.div`
  position: absolute;
  top: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  width: 500px;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);

  @media (max-width: 480px) {
    max-width: calc(100vw - 40px);
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 2;
  max-width: 100vw;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  cursor: pointer;
  color: #29465b;
  border-bottom: 1px solid grey;
  padding: 10px 0;
  z-index: 10;
`;

export const Bar = styled.div`
  position: relative;
  max-width: 800px;
  min-width: 400px;
  @media (max-width: 480px) {
    width: 100%;
    min-width: 0;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
`;

export const AttachmentsBtn = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.87);
  padding: 15px;
  cursor: pointer;
  margin-left: 10px;
  &:first-of-type {
    margin-left: 0;
  }
  @media screen and (max-width: 1440px) {
    svg {
      width: 16px;
    }
  }
`;

export const ToolBar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  margin: 10px 100px 10px 20px;
  @media (max-width: 600px) {
    position: relative;
    margin: 0;
    margin-top: 10px;
    margin-left: auto;
  }
`;

export const Header = styled.div`
  display: flex;
`;
