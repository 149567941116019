import { Button, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import ClearIcon from '@mui/icons-material/Clear';
import { useBoardContext } from '../../contexts';
import api from '../../services/ApiService';
import { TBoardCard } from '../card';
import { Column, TColumn } from '../column';
import AddIcon from '@mui/icons-material/Add';
import { AddColumn, ColumnList, Container, Title } from './styles';
import { MAX_COLUMNS } from '../../constants';
import { toast } from 'react-toastify';

export type TCards = Record<string, TBoardCard>;
export type TColumns = Record<string, TColumn>;

export type TBoard = {
  pk: number;
  name: string;
  owner: number;
  code: string;
  cards: TCards;
  columns: TColumns;
  columnOrder: string[];
  note: string;
  view_for_all: boolean;
  color_background: string;
};

export function Board({ board }: { board: TBoard }) {
  const { updateBoard } = useBoardContext();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.currentTarget.value);
  }

  async function handleCreate() {
    try {
      const data = await api.addColumn({
        title: name,
        board: board.pk,
      });
      const newColumn: TColumn = {
        ...data,
        cardIds: [],
        id: `column-${data.pk}`,
      };

      const newBoard: TBoard = {
        ...board,
        columnOrder: [...board.columnOrder, `column-${data.pk}`],
        columns: {
          ...board.columns,
          [`column-${data.pk}`]: newColumn,
        },
      };

      updateBoard(newBoard);
      handleClose();
    } catch (error: any) {
      console.log(error);
      if (error?.status === 403) {
        toast.error('Forbidden');
      }
    }
  }

  function handleAdd() {
    setOpen(true);
  }

  function handleClose(event?: React.MouseEvent<HTMLButtonElement>) {
    if (event) event.stopPropagation();
    setName('');
    setOpen(false);
  }

  return (
    <Container>
      <Title>{board.name}</Title>
      <Droppable droppableId="board" direction="horizontal" type="column">
        {provided => (
          <ColumnList {...provided.droppableProps} ref={provided.innerRef}>
            {board.columnOrder.map((columnId, index) => {
              const column = board.columns[columnId];
              const cards = column.cardIds.map(cardId => board.cards[cardId]);

              return (
                <Column
                  key={column.id}
                  column={column}
                  cards={cards}
                  index={index}
                />
              );
            })}
            {provided.placeholder}
            {board.columnOrder.length < MAX_COLUMNS && (
              <AddColumn onClick={handleAdd}>
                {open && (
                  <>
                    <div>
                      <TextField
                        type="text"
                        value={name}
                        onChange={onChange}
                        size="small"
                        sx={{ backgroundColor: '#fff' }}
                      />
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Button onClick={handleCreate}>Add</Button>
                      <IconButton onClick={handleClose}>
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </>
                )}
                {!open && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#1976d2',
                      fontWeight: 500,
                    }}
                  >
                    <AddIcon sx={{ marginRight: '5px' }} color="primary" />
                    ADD
                  </div>
                )}
              </AddColumn>
            )}
          </ColumnList>
        )}
      </Droppable>
    </Container>
  );
}
