import { createContext, useContext } from "react";
import ChatStore from "./Chat/ChatStore";
import RoomStore from "./Room/RoomStore";

interface Store {
    chatStore: ChatStore,
    roomState: RoomStore,
}

export const store: Store = {
    chatStore: new ChatStore(),
    roomState: new RoomStore(),
}

export const StoreContext = createContext(store);

export const useStore = () => {
    return useContext(StoreContext)
}
