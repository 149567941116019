import { ChangeEvent, useState } from 'react';

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import api, { TType } from '../../services/ApiService';
import DeleteIcon from '@mui/icons-material/Delete';

import { AddForm, List } from '../../pages/styles';
import { TBoardData } from '../../pages/board';
import { TypeSelect } from '../create-board-modal';
import useMember from '../../hooks/useMember';
import useAPI from '../../hooks/useAPI';
import { useBoardsContext } from '../../contexts';
import { InputWrapper } from '../../ui/styles';

export type TSelectMember = { id: number; share_type: TType };

type SelectProps = {
  member: TSelectMember;
  onEdit: (member: TSelectMember) => void;
};

export const ControlledTypeSelect = ({ member, onEdit }: SelectProps) => {
  const [value, setValue] = useState(member.share_type);

  function handleChange(e: SelectChangeEvent<TType>) {
    setValue(e.target.value as TType);
    onEdit({ id: member.id, share_type: e.target.value as TType });
  }

  return (
    <InputWrapper style={{ marginTop: 0, marginLeft: '10px' }}>
      <FormLabel htmlFor="type">Type</FormLabel>
      <Select
        defaultValue="COMMENTATOR"
        name="type"
        value={value}
        onChange={handleChange}
        style={{ width: 100 }}
      >
        <MenuItem value="ADMIN">Admin</MenuItem>
        <MenuItem value="EDITOR">User</MenuItem>
        <MenuItem value="COMMENTATOR">Viewer</MenuItem>
      </Select>
    </InputWrapper>
  );
};

type TProps = {
  board: TBoardData;
  handleClose: () => void;
};

export type TMember = {
  pk: number;
  owner: {
    pk: number;
    email: string;
    username: string;
  };
  email_user?: string;
  share_type: TType;
};

export default function EditBoardDialog({ board, handleClose }: TProps) {
  const {
    members,
    rmMembers,
    addMembers,
    editMembers,
    onRemove,
    onEdit,
    handleSubmit,
  } = useMember(board);

  const { boards, updateBoards } = useBoardsContext();

  const [name, setName] = useState(board.name);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  const shareBoardSafe = useAPI('shareBoard');

  async function onSave() {
    try {
      if (board.name !== name) {
        await api.editBoard(board.code, { name });
        const newBoardIndex = boards.findIndex(
          item => item.code === board.code
        );
        if (newBoardIndex !== -1) {
          const t = { ...boards[newBoardIndex] };
          t.name = name;
          const newBoards = [...boards];
          newBoards[newBoardIndex] = t;
          updateBoards(newBoards);
        }
      }

      const edit = editMembers.map(async member => {
        await api.editShare(member.id, member.share_type);
      });
      const remove = rmMembers.map(async member => {
        await api.deleteShare(member);
      });
      const add = addMembers.map(async member => {
        await shareBoardSafe({
          boardId: board.pk,
          email: member.email,
          share_type: member.share_type as TType,
        });
      });
      await Promise.all([...edit, ...remove, ...add]);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog
      open={!!board}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>Edit Members</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Board name"
          fullWidth
          variant="standard"
          value={name}
          onChange={onChange}
          sx={{ marginBottom: '20px' }}
        />
        <AddForm onSubmit={handleSubmit}>
          <InputWrapper>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField type="email" required name="email" />
          </InputWrapper>
          <TypeSelect />
          <IconButton type="submit">
            <AddBoxIcon fontSize="large" sx={{ marginLeft: '10px' }} />
          </IconButton>
        </AddForm>
        <List>
          {members.map(member => (
            <ListItem
              key={member.pk}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onRemove(member.pk)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={member.owner.email}
                secondary={member.share_type}
              />
              <ControlledTypeSelect
                member={{ id: member.pk, share_type: member.share_type }}
                onEdit={onEdit}
              />
            </ListItem>
          ))}
          {addMembers.map(member => (
            <ListItem
              key={member.email}
            // secondaryAction={
            //   <IconButton
            //     edge="end"
            //     aria-label="delete"
            //     onClick={() => onRemove(member.pk)}
            //   >
            //     <DeleteIcon />
            //   </IconButton>
            // }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={member.email}
                secondary={member.share_type}
              />
              {/* <ControlledTypeSelect
                member={{ id: member.pk, share_type: member.share_type }}
                onEdit={onEdit}
              /> */}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
