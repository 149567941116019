import Comments from './comment';
import { Box, InputLabel, Tab, Tabs } from '@mui/material';
import Invoices from '../invoices';
import { useState } from 'react';
import { useCardContext } from '../../contexts';
import Tasks from './tasks';
import Tags from './tags';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index,
  };
}

export default function CardTabs() {
  const { card } = useCardContext();

  const [value, setValue] = useState(!card?.topic ? 3 : 0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setValue(newValue);
  };

  if (!card) return null;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Tasks" {...a11yProps(0)} />
          <Tab label="Comments" {...a11yProps(1)} />
          {(card.card?.card_type === 'STU' || card.card?.card_type === 'CLI') && (
            <Tab label="Invoices" {...a11yProps(2)} />
          )}
          {!card.topic && <Tab label="Info" {...a11yProps(3)} />}
          <Tab label="Tags" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Tasks />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Comments comments={card.comments} />
      </TabPanel>
      {(card.card?.card_type === 'STU' || card.card?.card_type === 'CLI') && (
        <TabPanel value={value} index={2}>
          <Invoices />
        </TabPanel>
      )}
      {!card.topic && (
        <TabPanel value={value} index={3}>
          <div>
            <div style={{ margin: '10px 0' }}>
              <InputLabel>Phone</InputLabel>
              <span>{card.card?.phones[0]?.phone}</span>
            </div>
            <div style={{ margin: '10px 0' }}>
              <InputLabel>Full Name</InputLabel>
              <span>{card.card?.full_name}</span>
            </div>
            <div style={{ margin: '10px 0' }}>
              <InputLabel>Project</InputLabel>
              <span>{card.card?.project_name}</span>
            </div>
            <div style={{ margin: '10px 0' }}>
              <InputLabel>Course</InputLabel>
              <span>{card.card?.course}</span>
            </div>
          </div>
        </TabPanel>
      )}
      <TabPanel value={value} index={4}>
        <Tags />
      </TabPanel>
    </>
  );
}
