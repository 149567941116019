import { TBoard } from '../components/board';
import { TBoardData } from '../pages/board';

export const baseUrl =
  process.env.REACT_APP_SERVER_URL || 'http://127.0.0.1:8000';

type TCommentBody = {
  board_card: number;
  owner: number;
  board: number;
  text: string;
};

type TTaskBody = {
  title: string;
  board_card: number;
  deadline: string;
  board: number;
};

type TTagsBody = {
  title: string;
  board_card: number;
  board: number;
};

type TInvoiceBody = {
  title: string;
  sum: number;
  board_card: number;
  board: number;
  lang: string;
};

type TBoardCardBody = {
  status: number;
  board: number;
  creator: number;
  description?: string;
  type: string;

  tags_add?: string;
};

export type TCardBody = TBoardCardBody & {
  card_fields: {
    card_type: string;
    full_name: string;
    project_name: string;
    course?: string;
    phone: string;
    email: string;
    age?: string;

    balance?: number;
    price?: number;
  };
};

export type TTopicBody = TBoardCardBody & {
  topic_fields: {
    title: string;
  };
};

type TEditBoardCardBody = {
  status: number;
  board: number;
  creator: number;
  description?: string;

  phone?: string;
  email?: string;
};

export type TEditCardBody = TEditBoardCardBody & {
  card_fields: {
    card_type: string;
    full_name: string;
    project_name: string;
    course?: string;

    age?: string;
    balance?: number;
    price?: number;
    // phone: string;
  };
};

export type TEditTopicBody = TEditBoardCardBody & {
  topic_fields: {
    title: string;
  };
};

// type TEditCardBody = {
//   type: string;
//   full_name: string;
//   project_name: string;
//   course?: string;
//   description?: string;
//   // phone: string;
// };

type TSignupForm = {
  username: string;
  password: string;
  password2: string;
  email: string;
  first_name: string;
  last_name: string;
};

type TSigninForm = {
  username: string;
  password: string;
};

type THeaders =
  | ({
      Authorization?: string;
    } & {
      'Content-Type': 'application/json';
    })
  | {
      Authorization?: string;
    };

type TBoardBody = {
  name: string;
  owner: number;
};

type TColumnBody = {
  title: string;
  board: number;
};

export type TAttachment = {
  pk: number;
  file: string;
  // board: number;
};

export type TAttachmentsData = {
  results: TAttachment[];
};

export type TType = 'ADMIN' | 'EDITOR' | 'COMMENTATOR';

export type TShareBody = {
  boardId: number;
  email: string;
  share_type: TType;
};

export type TEditBoardBody = Partial<TBoardData>;

export type TEditTaskBody = {
  title?: string;
  done?: boolean;
  deadline?: string;
};

export type TEditTagsBody = {
  title?: string;
};

export type TConnectBody = {
  username: string;
  password: string;
  boardId: number;
}

export type TConnectLiqPayBody = {
  liqpay_public: string;
  liqpay_private: string;
}

export type TEmsStatusBody = {
  boardId: number;
}

export type TEmsToken = {
  token: string | null;
}

export type TKey = {
  key: string | null;
  webhook: string | null;
}


export class Api {
  _baseUrl: string;
  _headers: THeaders;
  _token?: string;

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl + '/';
    this._headers = {
      'Content-Type': 'application/json',
    };
  }

  setToken(token: string) {
    this._token = token;
  }

  removeToken() {
    this._token = undefined;
  }

  async checkReponse(res: Response) {
    if (res.ok) {
      try {
        const data = await res.json();
        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve('success');
      }
    } else {
      return Promise.reject(res);
    }
  }

  _makeRequest = (
    endPoint: string,
    method = 'GET',
    body?: any,
    isFile = false
  ) => {
    let headers = this._headers;
    if (this._token) {
      headers = !isFile
        ? {
            ...this._headers,
            Authorization: `Token ${this._token}`,
          }
        : { Authorization: `Token ${this._token}` };
    }

    return fetch(this._baseUrl + endPoint, {
      method,
      headers,
      body: isFile ? body : JSON.stringify(body),
    })
      .then((res) => {
        return this.checkReponse(res);
      })
      .catch(async (err) => {
        return Promise.reject(err);
      });
  };

  async moveColumn(column: number, order: number) {
    return this._makeRequest(`board/api/columns/${column}/move/`, 'PUT', {
      order,
    });
  }

  async changeStatus(card: number, status: number) {
    return this._makeRequest(`board/api/cards/${card}/`, 'PATCH', { status });
  }

  async moveCard(card: number, order: number, status?: number) {
    if (status) {
      await this.changeStatus(card, status);
    }
    return this._makeRequest(`board/api/cards/${card}/move/`, 'PUT', { order });
  }

  async deleteColumn(column: number) {
    return this._makeRequest(`board/api/columns/${column}/`, 'DELETE');
  }

  async editColumn(column: number, title: string) {
    return this._makeRequest(`board/api/columns/${column}/`, 'PATCH', {
      title,
    });
  }

  async addComment(body: TCommentBody) {
    return await this._makeRequest('board/api/comments/', 'POST', body);
  }

  async addTask(body: TTaskBody) {
    return await this._makeRequest(`board/api/tasks/`, 'POST', body);
  }

  async addInvoice(body: TInvoiceBody) {
    return await this._makeRequest(`board/api/invoices/`, 'POST', body);
  }

  async doneInvoice(id: number) {
    return this._makeRequest(
      `board/api/invoices/${id}/done/`,
      'POST',
      null
    );
  }

  async deleteInvoice(id: number) {
    this._makeRequest(`board/api/invoices/${id}/`, 'DELETE');
  }


  async addTags(body: TTagsBody) {
    return await this._makeRequest(`board/api/tags/`, 'POST', body);
  }

  async editTags(id: number, body: TEditTagsBody) {
    return await this._makeRequest(`board/api/tags/${id}/`, 'PATCH', body);
  }

  async deleteTags(id: number) {
    this._makeRequest(`board/api/tags/${id}/`, 'DELETE');
  }



  async editTask(id: number, body: TEditTaskBody) {
    return await this._makeRequest(`board/api/tasks/${id}/`, 'PATCH', body);
  }

  async addCard(body: TCardBody | TTopicBody) {
    return await this._makeRequest('board/api/cards/', 'POST', body);
  }

  async editCard(body: TEditCardBody | TEditTopicBody, id: number) {
    return await this._makeRequest(`board/api/cards/${id}/`, 'PUT', body);
  }

  async shareBoard(body: TShareBody) {
    return await this._makeRequest(`board/api/share/`, 'POST', body);
  }

  async signUp(body: TSignupForm) {
    return this._makeRequest('core/profile/', 'POST', body);
  }

  async signIn(body: TSigninForm) {
    return this._makeRequest('core/token/', 'POST', body);
  }

  async getBoards() {
    return this._makeRequest('board/api/boards/', 'GET');
  }

  async getSharedBoards() {
    return this._makeRequest('board/api/share/', 'GET');
  }

  async getSharedMembers(id: number) {
    return this._makeRequest(`board/api/share/${id}/board/`, 'GET');
  }

  async confirmEmail(userId: string, token: string) {
    return this._makeRequest(
      `core/profile/activate?user_id=${userId}&confirmation_token=${token}`,
      'GET'
    );
  }

  async createBoard(body: TBoardBody) {
    return this._makeRequest('board/api/boards/', 'POST', body);
  }

  async getBoard(code: string): Promise<TBoardData> {
    return this._makeRequest(`board/api/boards/${code}/`, 'GET');
  }

  async search(code: string, query: string) {
    return this._makeRequest(
      `board/api/boards/${code}/search?q=${query}`,
      'GET'
    );
  }

  async getProfile() {
    return await this._makeRequest(`core/profile/me/`, 'GET');
  }

  async removeCard(card: number) {
    this._makeRequest(`board/api/cards/${card}/`, 'DELETE');
  }

  async addColumn(body: TColumnBody) {
    return this._makeRequest('board/api/columns/', 'POST', body);
  }

  async attachFile(body: FormData, id: number, type: 'tasks' | 'comments') {
    return this._makeRequest(
      `board/api/${type}/${id}/attach/`,
      'POST',
      body,
      true
    );
  }

  async deleteTask(task: number) {
    this._makeRequest(`board/api/tasks/${task}/`, 'DELETE');
  }

  async spentHrTask(task: number, body: {spent: string}) {
    return this._makeRequest(
      `board/api/tasks/${task}/spent/`,
      'POST',
      body
    );
  }

  async getAttachments(code: string): Promise<TAttachmentsData> {
    return await this._makeRequest(
      `board/api/attachments/?board=${code}`,
      'GET'
    );
  }

  async editShare(id: number, share_type: TType) {
    return await this._makeRequest(`board/api/share/${id}/`, 'PATCH', {
      share_type,
    });
  }

  async deleteShare(id: number) {
    this._makeRequest(`board/api/share/${id}/`, 'DELETE');
  }

  async editBoard(code: string, boardBody: TEditBoardBody) {
    return await this._makeRequest(
      `board/api/boards/${code}/`,
      'PATCH',
      boardBody
    );
  }

  async deleteBoard(code: string) {
    this._makeRequest(`board/api/boards/${code}/`, 'DELETE');
  }

  async assignUser(body: { assigned: number }, id: number) {
    return this._makeRequest(`board/api/cards/${id}/assigned/`, 'POST', body);
  }

  async getMembers(code: string) {
    return await this._makeRequest(`board/api/cards/${code}/assigneds`, 'GET');
  }

  async connectEms(connectBody: TConnectBody) {
    return await this._makeRequest(
      `ems/connect/`,
      'POST',
      connectBody
    );
  }

  async checkConnectEms(connectBody: TEmsStatusBody) {
    return await this._makeRequest(
      `ems/status/`,
      'POST',
      connectBody
    );
  }

  async checkConnectLiqPay(code: string) {
    return this._makeRequest(
      `board/api/boards/${code}/liqpay/`,
      'GET'
    );
  }

  async getKey(code: string) {
    return this._makeRequest(
      `board/api/key/${code}/`,
      'GET'
    );
  }


  async connectLiqPay(code: string, connectBody: TConnectLiqPayBody) {
    return this._makeRequest(
      `board/api/boards/${code}/liqpay_connect/`,
      'POST',
      connectBody
    );
  }

  async createKey(code: string) {
    return this._makeRequest(
      `board/api/key/${code}/create`,
      'POST'
    );
  }

  async disconnectLiqPay(code: string) {
    return await this._makeRequest(
      `board/api/boards/${code}/liqpay_disconnect/`,
      'POST'
    );
  }

  async synchronizationEms(syncBody: TEmsStatusBody) {
    return await this._makeRequest(
      `ems/synchronization/`,
      'POST',
      syncBody
    );
  }

  async disconnectEms(syncBody: TEmsStatusBody) {
    return await this._makeRequest(
      `ems/disconnect/`,
      'POST',
      syncBody
    );
  }
}

const api = new Api(baseUrl);

export default api;
