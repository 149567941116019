import {
  Button,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  TDCard,
  useAuthContext,
  useBoardContext,
  useCardContext,
  useImageContext,
} from '../../contexts';
import api, { baseUrl } from '../../services/ApiService';
import { TComment, TFile } from '../card';
import {
  CommentList,
  CommentForm,
  Comment,
  Img,
  InputWrapper,
  Files,
  StyledLink,
} from './styles';
import { useTextWithUrls } from '../../hooks/useEditableWithUrls';
import useFiles from './useFiles';
import { Attach } from './add-task-modal';
import AddAttachments from './add-attachments';

type TProps = {
  comments: TComment[];
};

export const Attachments = ({
  attachments,
  setImage,
}: {
  attachments: TFile[];
  setImage: (img: string) => void;
}) => (
  <Files style={{ display: 'block' }}>
    {attachments.map(file => (
      <StyledLink
        href={file.file}
        target="_blank"
        rel="noopener noreferrer"
        key={file.pk}
      >
        {getContent(file.file, setImage)}
        <span style={{ marginLeft: '5px' }}>
          {file.file.split('/').slice(-1)[0]}
        </span>
      </StyledLink>
    ))}
  </Files>
);

const CommentItem = ({
  comment,
  setImage,
}: {
  comment: TComment;
  setImage: (image: string) => void;
}) => {
  const render = useTextWithUrls(comment.text);

  return (
    <List>
      <ListItem style={{ display: 'block' }}>
        {render()}
        <ListItemText
          primary={moment(comment.created_on).format('DD/MM/YYYY, hh:mm')}
          secondary={
            comment.owner_info.first_name + ' ' + comment.owner_info.last_name
          }
        />
        <Attachments attachments={comment.attachments} setImage={setImage} />
      </ListItem>
    </List>
  );
};

const re = /\..+$/;

function getContent(url: string, setImg: (url: string) => void) {
  const ext = url.split('/').slice(-1)[0].match(re);
  if (ext) {
    switch (ext[0].slice(1)) {
      case 'ppt':
      case 'pptx':
        return <SlideshowIcon fontSize="large" />;
      case 'doc':
      case 'docx':
        return <ArticleIcon fontSize="large" />;
      case 'pdf':
        return <PictureAsPdfIcon fontSize="large" />;
      case 'png':
      case 'jpg':
      case 'jpeg':
        return (
          <Img
            src={url}
            onClick={e => {
              e.preventDefault();
              setImg(url);
            }}
          />
        );
    }
  }
  return '';
}

export default function Comments({ comments }: TProps) {
  const [text, setText] = useState('');

  const { setCard, card } = useCardContext();
  const { updateBoard, board } = useBoardContext();
  const { profile } = useAuthContext();

  const updateCard = (card: TDCard, comment: TComment) => {
    const newCard = { ...card };
    newCard.comments.push(comment);
    setCard(newCard);
    const newBoard = { ...board };
    newBoard.cards[card.id] = newCard;
    updateBoard(newBoard);
  };

  const { files, setFiles, onChange, handleDelete } = useFiles();

  const [required, setRequired] = useState(false);

  const handleAddComment = async () => {
    if (!card) return; // typescript check
    if (!text) {
      setRequired(true);
    }
    try {
      const comment: TComment = await api.addComment({
        board_card: card.pk,
        owner: profile.pk,
        board: card.board,
        text,
      });
      const promises = files?.map(async file => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', file.name);
        const data: TFile = await api.attachFile(
          formData,
          comment.pk,
          'comments'
        );
        data.file = baseUrl + data.file;
        comment.attachments.push(data);
      });
      if (promises) {
        await Promise.all(promises);
      }
      updateCard(card, comment);
      setText('');
      setFiles(null);
      setRequired(false);
    } catch (error) {
      console.log(error)
    }
  };

  const { setImage } = useImageContext();

  return (
    <>
      <Comment>
        <InputLabel>Comments</InputLabel>
        <CommentList>
          {[...comments].reverse().map(comment => (
            <CommentItem
              key={`comment-${comment.pk}`}
              comment={comment}
              setImage={(img: string) => setImage(img)}
            />
          ))}
        </CommentList>
        <CommentForm>
          <div style={{ marginBottom: '15px', width: '100%' }}>
            <AddAttachments files={files} handleDelete={handleDelete} />
            <InputWrapper>
              <Attach onChange={onChange} id="comment" />
              <div style={{ width: '100%' }}>
                <TextField
                  fullWidth
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
                {required && <span style={{ color: 'red' }}>Required</span>}
              </div>
              {/* <CommentInput value={text} onChange={handleChange} /> */}
            </InputWrapper>
          </div>
          <Button
            variant="outlined"
            onClick={handleAddComment}
            sx={{ width: 'fit-content' }}
          >
            Add Comment
          </Button>
        </CommentForm>
      </Comment>
    </>
  );
}
