import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import io from 'socket.io-client';
import { useStore } from '../stores';

export type SendMessageT = {
  chatId: number;
  token?: string;
  text?: string;
  file?: Record<string, string>;
};

// const SERVER_URL =
// process.env.REACT_APP_SERVER_URL + '/chat';
const SERVER_URL = process.env.REACT_APP_SERVER_CHAT_SOCKET || 'http://localhost:3000/chat';

export function useSocket() {
  const socketRef = useRef<any>();
  const [notifications, setNotifications] = useState(0);

  const {
    chatStore: {
      sendMessage: sendMessageStore,
      setNotRead,
    }
  } = useStore();

  useEffect(() => {
    socketRef.current = io(SERVER_URL, {
      transports : ['websocket'],
    });
    socketRef.current.on('joinedRoom', (item: any) => {
      console.log(item, 'room connected');
    });
    socketRef.current.on('hasLeaveRoom', (item: any) => {
      console.log(item, 'room leave Room');
    });
    socketRef.current.on('msgToClient', (message: any) => {
      if (socketRef.current) {
        sendMessageStore(message);
        // socketRef.current.emit('readMessage', {
        //   id: message.id,
        //   boardId: message.boardId,
        // });
      }
    });
    socketRef.current.on(
        'listeningMessages',
        ({ count }: { count: number }) => {
          if (socketRef.current) {
            setNotifications(count);
          }
        }
    );

    return () => {
      disconnect()
    }
  }, []);

  const sendMessage = (messageBody: SendMessageT) => {
    if (socketRef.current) {
      socketRef.current.emit('exchanges', messageBody);
    }
  };

  const joinRoom = (data: { chatId: number}) => {
    if (socketRef.current && data.chatId) {
      socketRef.current.emit('joinRoom', data);
    }
  };

  const leaveRoom = (data: { chatId: number }) => {
    if (socketRef.current) {
      if (data.chatId) {
        socketRef.current.emit('leaveRoom', data);
      }
    }
  };

  const joinListeningMessages = (boardId: number) => {
    if (socketRef.current) {
      socketRef.current.emit('joinListeningMessages', {
        boardId,
      });
    }
  };

  function disconnect() {
    if (socketRef.current) socketRef.current.disconnect();
  }

  return {
    joinRoom,
    leaveRoom,
    joinListeningMessages,
    notifications,
    sendMessage,
    disconnect,
    socket: socketRef.current,
  };
}
