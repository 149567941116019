import { makeAutoObservable, runInAction } from 'mobx';
import { ApiServiceChat } from '../ApiService';

type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}

type MessageT = {
    id?: number;
    facebook_user?: string;
    user_id?: number;
    text?: string | null;
    file?: Record<string, string> | null;
    createdAt?: Date;
}

export default class ChatStore {
    isLoading = true;
    messages: MessageT[] = [];
    chatId?: number | null;
    newMessageCount = 0;
    notRead = 0;
    error?: ErrorT | string;

    lastAbortController: any;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    sendMessage(message: MessageT) {
        this.messages = [
            ...this.messages,
            message
        ];
    }

    clearMessages() {
        this.messages = [];
    }

    setNotRead(count: number) {
        this.notRead = count;
    }

    setNewMessageCount(count: number) {
        this.newMessageCount = count;
    }

    setError(error: ErrorT | string) {
        this.error = error;
    }

    setChatId(chatId?: number | null) {
        this.chatId = chatId;
    }

    clearChatStore() {
        this.chatId = null;
        this.notRead = 0;
        this.messages = [];
    }

    getChat(id: string, fbUser: string, token: string) {
        this.isLoading = true;

        if (this.lastAbortController) {
            this.lastAbortController.abort();
        }

        const abortController = new AbortController();
        this.lastAbortController = abortController;

        ApiServiceChat({
            token,
            endPoint: `chat/get-chat/${id}?page=1&senderId=${fbUser}`,
            method: 'GET',
            headers: {'application': localStorage.getItem('appId')},
            signal: abortController.signal,
        }).then((res) => {
            if (abortController.signal.aborted) {
                return new Promise(() => undefined);
            }
            runInAction(() => {
                this.messages = res.messages;
                this.chatId = res.chatId;
            });
            this.isLoading = false;
        });
    }

    chatRead(chatId: number, token: string) {
        ApiServiceChat({
            token,
            endPoint: `chat/read/${chatId}`,
            method: 'PUT',
        }).then((res) => {
            runInAction(() => {
                this.notRead = 0;
                this.isLoading = false;
            });
        });
    }
}
