import { Fragment, useEffect, useState } from 'react';

export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/gi;

function getUrls(text: string) {
  return text.matchAll(urlRegex);
}

export function useTextWithUrls(text: string) {
  const [urls, setUrls] = useState<RegExpMatchArray[]>();

  useEffect(() => {
    const all = Array.from(getUrls(text));
    console.log(text, all);
    setUrls([...all]);
  }, [text]);

  const renderWithUrls = () => {
    return urls && urls.length > 0 ? (
      <>
        {text.slice(0, urls[0].index)}
        {urls.map((url, index) => {
          return (
            <Fragment key={index}>
              {url.index !== undefined && (
                <a href={url[0]} target="_blank" rel="noreferrer">
                  {text.slice(url.index, url.index + url[0].length)}
                </a>
              )}
              {index < urls.length - 1 &&
                url.index !== undefined &&
                text.slice(url.index + url[0].length, urls[index + 1].index)}
            </Fragment>
          );
        })}
        {urls[urls.length - 1].index !== undefined &&
          text.slice(
            (urls[urls.length - 1].index ?? 0) +
              urls[urls.length - 1][0].length,
            text.length
          )}
      </>
    ) : (
      text
    );
  };

  return renderWithUrls;
}
