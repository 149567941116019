interface ApiServiceT {
  token: string,
  endPoint: string,
  method: string,
  body?: any,
  headers?: object,
  signal?: AbortSignal | null,
}

export const ApiServiceChat = (
  {
    token,
    endPoint,
    method = 'GET',
    body,
    headers,
    signal = null,
  }: ApiServiceT
): Promise<any> => {
    const baseUrl =
        process.env.REACT_APP_SERVER_CHAT_URL || 'http://localhost:3000';

    const apiBase = baseUrl + '/';
    const headersData = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'apiKey': token,
        ...headers
    };
    return fetch(apiBase + endPoint, {
        method,
        headers: headersData,
        body: JSON.stringify(body),
        signal
    })
        .then((res) => {
            return res;
        })
        .then((res) => res.json())
}