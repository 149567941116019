import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import { EmailConfirmation } from './pages/auth/email-confirmation';
import { End } from './pages/auth/end';
import { Signin } from './pages/auth/signin';
import { Signup } from './pages/auth/signup';
import { BoardPage } from './pages/board';
import BoardListPage from './pages/board-list';
import ProtectedRoutes from './services/ProtectedRoute';
import 'react-toastify/dist/ReactToastify.css';
import useAuth, { TProfile } from './hooks/useAuth';
import { AuthContext } from './contexts';
import StatisticsPage from './pages/statistics';
import Chat from './pages/Chat/Chat';

function App() {
  const { loggedIn, profile, setProfile, handleSignin, handleLogout } =
    useAuth();

  console.log('test', loggedIn);
  console.log('test 2', profile);

  if (loggedIn === null || !profile) return null;

  return (
    <div className="App">
      <ToastContainer />
      <AuthContext.Provider
        value={{
          onLogout: handleLogout,
          profile,
          setProfile: (profile: TProfile) => setProfile(profile),
        }}
      >
        <Routes>
          <Route path="/signin" element={<Signin onSignin={handleSignin} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/end" element={<End />} />
          <Route path="/confirm" element={<EmailConfirmation />} />
          <Route
            path="/"
            element={
              <ProtectedRoutes auth={loggedIn}>
                <BoardListPage />
              </ProtectedRoutes>
            }
          />
          <Route path="/board/:code" element={<BoardPage />} />
          <Route path="/board/statistics" element={<StatisticsPage />} />
          <Route
            path="/board/:token/chat"
            element={
              <ProtectedRoutes auth={loggedIn}>
                <Chat />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/board/:token/chat/:id"
            element={
              <ProtectedRoutes auth={loggedIn}>
                <Chat />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
