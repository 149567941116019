import React, { useEffect, useMemo } from 'react';
import {
    Badge,
    Grid,
    List,
    ListItem,
    ListItemText
} from "@mui/material";
import {
    AvatarBlock,
    ChatItem,
    NameChat,
    RoomListBlock
} from "./style";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {useStore} from "../../../../stores";


interface RoomListT {
    id?: string;
    token: string;
}

export const RoomList = observer(({ id, token }: RoomListT) => {
    const {
        roomState: {
            isLoading,
            rooms,
            selectRoom,
            getRooms,
            setSelected,
        },
        chatStore: {
            newMessageCount,
        }
    } = useStore();

    const navigate = useNavigate();

    useEffect(() => {
        getRooms(token)
        return () => {
            setSelected(null);
        }
    }, []);

    useEffect(() => {
        if (id && !selectRoom && !isLoading) {
            setSelected(Number(id));
        }
    }, [id, selectRoom, isLoading]);

    useEffect(() => {
        if (Number.isInteger(newMessageCount)) {
            getRooms(token);
        }
    }, [newMessageCount])

    const locationChat = (id: number) => {
        setSelected(id);
        navigate(`/board/${token}/chat/${id}`)
    };

    const roomsRender = useMemo(() => {
        return rooms?.map((el) => (
            <ChatItem
                onClick={() => locationChat(el.id)}
                key={el.id}
                className={selectRoom?.id === el.id ? 'active' : ''}
            >
                <ListItemText primary={el.facebook_user_name || `User ${el.id}`}>
                    {el.facebook_user_name || `User ${el.id}`}
                </ListItemText>
            </ChatItem>
        ));
    }, [rooms, selectRoom]);

    return (
        <>
            <Grid item xs={3} sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <RoomListBlock>
                    {isLoading ? (
                        <>...Loading</>
                    ) : roomsRender}
                </RoomListBlock>
            </Grid>
        </>
    );
});

export default RoomList;
