import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TColumn } from '../components/column';
import { Header } from '../components/header/header';
import Statistics from '../components/statistics';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export type TBoardData = {
  pk: number;
  name: string;
  owner: number;
  code: string;
  columns: TColumn[];
  note: string;
  view_for_all: boolean;
};

export default function StatisticsPage() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Flex>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon color="primary" fontSize="large" />
        </IconButton>
        <H1>Statistics</H1>
      </Flex>
      <Wrapper>
        <Statistics />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
`;

const H1 = styled.h1`
  margin: 0;
  margin-left: 5px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
`;
