import { Dialog } from '@mui/material';

export default function Image({
  image,
  setImage,
}: {
  image: string;
  setImage: (image: string) => void;
}) {
  return (
    <>
      {image && (
        <Dialog open={!!image} onClose={() => setImage('')}>
          <img
            src={image}
            alt="img"
            style={{ objectFit: 'contain', width: '100%' }}
          />
        </Dialog>
      )}
    </>
  );
}
