import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
