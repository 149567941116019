import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, MenuItem, Select, TextField, SelectChangeEvent, IconButton } from '@mui/material';
import { TBoardCard } from '../card';
import { FormLabel, TextareaAutosize } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormContainer } from './styles';
import { useBoardContext, useCardContext } from '../../contexts';
import api, { TEditCardBody, TEditTopicBody } from '../../services/ApiService';
import CloseIcon from '@mui/icons-material/Close';
import { Wrapper } from '../new-card-modal/styles';

export function EditCardDialog(props: { open: boolean; onClose: () => void }) {
  const { onClose, open } = props;
  const matches = useMediaQuery('(max-width:480px)');

  const { board, updateBoard } = useBoardContext();

  const { card, setCard } = useCardContext();

  function updateCard(newCard: TBoardCard) {
    if (card) {
      console.log(newCard);
      setCard({ ...newCard, index: card?.index });
      const newBoard = { ...board };
      newBoard.cards[card.id] = newCard;
      updateBoard(newBoard);
    }
  }

  async function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    let body: TEditCardBody | TEditTopicBody;

    if (card) {
      if (card?.topic) {
        const target = e.target as typeof e.target & {
          title: { value: string };
          description: { value: string };
        };
        body = {
          board: card?.board,
          status: card?.status,
          creator: card?.creator,
          description: target.description.value,
          topic_fields: {
            title: target.title.value,
          },
        };
      } else {
        const target = e.target as typeof e.target & {
          type: { value: string };
          full_name: { value: string };
          project_name: { value: string };
          course: { value: string };
          description: { value: string };
          phone: { value: string };
          email: { value: string };
          price: { value: number };
          balance: { value: number };
          age: { value: string };
        };
        body = {
          board: card?.board,
          status: card?.status,
          creator: card?.creator,
          description: target.description.value,
          phone: target.phone.value,
          email: target.email.value,
          card_fields: {
            card_type: target.type.value,
            full_name: target.full_name.value,
            project_name: target.project_name.value,
            ...(type === 'STU' || type === 'CLI' ? { course: target.course.value } : {}),
            age: target.age.value || '0',
            balance: target.balance.value,
            price: target.price.value,
            // phone: target.phone.value,
          },
        };
      }
      try {
        if (card) {
          const newCard = await api.editCard(body, card.pk);
          updateCard(newCard);
        }
        onClose();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const [type, setType] = useState<'TOPIC' | 'CLI' | 'STU' | 'DEV' | undefined>('TOPIC');

  function handleChange(e: SelectChangeEvent<'TOPIC' | 'CLI' | 'STU' | 'DEV'>) {
    setType(e.target.value as 'TOPIC' | 'CLI' | 'STU' | 'DEV');
  }

  useEffect(() => {
    if (card) {
      setType(card?.card?.card_type as 'TOPIC' | 'CLI' | 'STU' | 'DEV' | undefined);
    }
  }, [card]);

  return (
    <Dialog onClose={onClose} open={!!open}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Wrapper>
          <p style={{ margin: '10px 0 0' }}>
            Edit Card
          </p>
        </Wrapper>
      </DialogTitle>
      <DialogContent sx={{ minWidth: !matches ? '350px' : '300px' }}>
        <form onSubmit={handleSubmit}>
          <FormContainer>
            {card?.topic && (
              <>
                <FormLabel>Title</FormLabel>
                <TextField
                  name="title"
                  variant="outlined"
                  defaultValue={card?.topic.title}
                />
              </>
            )}
            <FormLabel>Description</FormLabel>
            <TextareaAutosize
              style={{
                fontSize: '18px',
                minWidth: '100%',
                fontFamily: 'Roboto',
              }}
              aria-label="empty textarea"
              placeholder="Empty"
              name="description"
              minRows={5}
              defaultValue={card?.description}
            />
            {card?.card && (
              <>
                <FormLabel>Type</FormLabel>
                <Select name="type" onChange={handleChange} value={type}>
                  <MenuItem value="CLI">Client</MenuItem>
                  <MenuItem value="STU">Student</MenuItem>
                  <MenuItem value="DEV">Developer</MenuItem>
                  <MenuItem value="TOPIC">Topic</MenuItem>
                </Select>
                <FormLabel>Full Name</FormLabel>
                <TextField
                  name="full_name"
                  variant="outlined"
                  defaultValue={card?.card.full_name}
                />
                <FormLabel>Project Name</FormLabel>
                <TextField
                  name="project_name"
                  variant="outlined"
                  required
                  defaultValue={card?.card.project_name}
                />
                <FormLabel>Phone</FormLabel>
                <TextField
                  name="phone"
                  variant="outlined"
                  required
                  defaultValue={card?.card.phones[0]?.phone}
                />
                <FormLabel>Email</FormLabel>
                <TextField
                  name="email"
                  type="email"
                  variant="outlined"
                  defaultValue={card?.card.emails[0]?.email}
                />
                <FormLabel>Balance</FormLabel>
                <TextField
                  name="balance"
                  type="text"
                  variant="outlined"
                  defaultValue={card?.card?.balance}
                />
                <FormLabel>Price</FormLabel>
                <TextField
                  name="price"
                  type="text"
                  variant="outlined"
                  defaultValue={card?.card?.price}
                />
                {(type === 'STU' || type === 'CLI') && (
                  <>
                    <FormLabel>Age</FormLabel>
                    <TextField
                      name="age"
                      type="date"
                      variant="outlined"
                      defaultValue={card?.card?.age}
                    />
                    <FormLabel>Course</FormLabel>
                    <TextField
                      name="course"
                      type="text"
                      variant="outlined"
                      defaultValue={card?.card.course}
                    />
                  </>
                )}
              </>
            )}
            {card?.fblead && card.fblead.fields.map((el) => (
              <>
                <FormLabel>{el.name}</FormLabel>
                <TextField
                  name={el.name}
                  disabled={true}
                  variant="outlined"
                  defaultValue={el.value}
                />
              </>
            ))}
          </FormContainer>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            sx={{ marginTop: '15px' }}
            fullWidth
          >
            Save
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
