import React, { createContext, useContext } from 'react';
import { TProfile } from '../hooks/useAuth';
import { TBoard } from '../components/board';
import { TBoardCard } from '../components/card';
import { TBoardData } from '../pages/board';
import { TAssigner } from '../hooks/useMembers';

export type TBoardContext = {
  board: TBoard;
  updateBoard: (newBoard: TBoard) => void;
};

export type TDCard = TBoardCard & { index: number };

export type TCardContext = {
  card: TDCard | null;
  setCard: (card: TDCard | null) => void;
};

export type TNewCardConetxt = {
  open: number;
  setOpen: (open: number) => void;
};

export type TImageContext = {
  setImage: (image: string) => void;
};

export const BoardContext = createContext<TBoardContext>({} as TBoardContext);
export const CardContext = createContext<TCardContext>({} as TCardContext);
export const NewCardContext = createContext<TNewCardConetxt>(
  {} as TNewCardConetxt
);
export const ImageContext = createContext<TImageContext>({} as TImageContext);

export function useBoardContext() {
  return useContext(BoardContext);
}

export function useCardContext() {
  return useContext(CardContext);
}

export function useNewCardContext() {
  return useContext(NewCardContext);
}

export function useImageContext() {
  return useContext(ImageContext);
}

// *************************************************************

export type TAuthContext = {
  onLogout: () => void;
  profile: TProfile;
  setProfile: (profile: TProfile) => void;
};

export const AuthContext = React.createContext<TAuthContext>(
  {} as TAuthContext
);

export const useAuthContext = () => useContext(AuthContext);

// *************************************************************

export type TMembersContext = {
  isAdmin: (id: number) => void;
  isEditor: (id: number) => void;
  isCommentator: (id: number) => void;
  assigners: TAssigner[];
};

export const MembersContext = React.createContext<TMembersContext>(
  {} as TMembersContext
);

export const useMembersContext = () => useContext(MembersContext);

// *************************************************************

export type TBoardsContext = {
  boards: TBoardData[];
  updateBoards: (boards: TBoardData[]) => void;
};

export const BoardsContext = React.createContext<TBoardsContext>(
  {} as TBoardsContext
);

export const useBoardsContext = () => useContext(BoardsContext);
