import { SyntheticEvent, useEffect, useState } from 'react';

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddBoxIcon from '@mui/icons-material/Add';

import { useBoardContext } from '../../contexts';
import api, { TType } from '../../services/ApiService';

import {
  ControlledTypeSelect,
  TMember,
  TSelectMember,
} from '../edit-board-modal';
import { AddForm } from '../../pages/styles';
import { TypeSelect } from '../create-board-modal';
import { toast } from 'react-toastify';
import { InputWrapper } from '../../ui/styles';
import { Share } from '@mui/icons-material';
import styled from 'styled-components';

type TProps = {
  open: boolean;
  onClose: () => void;
};

export default function MembersModal({ open, onClose }: TProps) {
  const { board, updateBoard } = useBoardContext();

  const [members, setMembers] = useState<TMember[]>([]);

  useEffect(() => {
    if (board) {
      (async function () {
        try {
          const data = await api.getSharedMembers(board.pk);
          setMembers(data);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [board]);

  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
      type: { value: string };
    };

    setAddMembers(old => [
      ...old,
      {
        email: target.email.value,
        share_type: target.type.value,
      },
    ]);
    (e.target as any).reset();
  }

  const [rmMembers, setRmMembers] = useState<number[]>([]);
  const [addMembers, setAddMembers] = useState<
    {
      email: string;
      share_type: string;
    }[]
  >([]);
  const [editMembers, setEditMembers] = useState<TSelectMember[]>([]);

  function onRemove(id: number) {
    setRmMembers(old => [...old, id]);
    setMembers(old => old.filter(member => member.pk !== id));
  }

  function onEdit(member: TSelectMember) {
    setEditMembers(old => [...old, member]);
  }

  async function onSave() {
    try {
      await api.editBoard(board.code, { view_for_all: checked });
      const newBoard = { ...board };
      newBoard.view_for_all = checked;
      updateBoard(newBoard);

      const edit = editMembers.map(async member => {
        await api.editShare(member.id, member.share_type);
      });
      const remove = rmMembers.map(async member => {
        await api.deleteShare(member);
      });
      const add = addMembers.map(async member => {
        await api.shareBoard({
          boardId: board.pk,
          email: member.email,
          share_type: member.share_type as TType,
        });
      });
      await Promise.all([...edit, ...remove, ...add]);
      onClose();
    } catch (error: any) {
      console.log(error);
      if (error?.status === 403) {
        toast.error('Forbidden');
      }
    }
  }

  const [checked, setChecked] = useState(board.view_for_all);

  return (
    <Dialog open>
      <DialogTitle>Edit Members</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
            />
          }
          label="Share by Link"
        />
        <AddForm onSubmit={handleSubmit}>
          <InputWrapper style={{ width: '100%' }}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField type="email" required name="email" />
          </InputWrapper>
          <div style={{ display: 'flex' }}>
            <TypeSelect />
            <IconButton type="submit">
              <AddBoxIcon fontSize="large" />
            </IconButton>
          </div>
        </AddForm>
        <List>
          {members.map((member, key) => (
            <Li key={`${member?.owner?.pk}-${key}`}>
              <Flex>
                <ListItemAvatar>
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                {member?.email_user ? (
                  <ListItemText
                    primary={member?.email_user}
                    secondary="Invited"
                  />
                ) : member.owner && (
                  <ListItemText
                    primary={member.owner.email}
                    secondary={member.share_type}
                  />
                )}
              </Flex>
              <Flex>
                <ControlledTypeSelect
                  member={{ id: member.pk, share_type: member.share_type }}
                  onEdit={onEdit}
                />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onRemove(member.pk)}
                >
                  <DeleteIcon />
                </IconButton>
              </Flex>
            </Li>
          ))}
          {addMembers.map(member => (
            <Li
              key={member.email}
              // secondaryAction={
              //   <IconButton
              //     edge="end"
              //     aria-label="delete"
              //     onClick={() => onRemove(member.pk)}
              //   >
              //     <DeleteIcon />
              //   </IconButton>
              // }
            >
              <Flex>
                <ListItemAvatar>
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={member.email}
                  secondary={member.share_type}
                />
              </Flex>
            </Li>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

const Li = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
