import styled from 'styled-components'

export const AvatarBlock = styled.div`
  background-color: #0000b3;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  transition: 0.3s;
  text-align: left;
  cursor: pointer;
  svg {
    margin-right: 20px;
  }
  &:hover {
    background-color: #809fff;
    color: white;
  }
`;

export const ChatItem = styled(MenuItem)`
  margin: 5px 10px;
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    margin-bottom: 10px;
  }
  svg {
    margin: 0;
  }
  &.active {
    background-color: #bfcfff;
    color: black;
  }
`;

export const RoomListBlock = styled.div`
  border-radius: 15px;
  box-shadow: none;
  border: 1px solid #E7EBF0;
  margin: 0 0 0 10px;
  flex: 1 1 0;
  -ms-flex: 1 1 1%;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const NameChat = styled.div`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;