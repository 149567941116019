import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SyntheticEvent, useState } from 'react';
import { useUpdateCard } from '../../hooks';
import useAPI from '../../hooks/useAPI';
import { InputWrapper } from '../../ui/styles';
import { TBoardCard, TInvoice } from '../card';
import { TTask } from '../task';

type TProps = { invoice: TInvoice; onClose: () => void };

export default function EditInvoice({ invoice, onClose }: TProps) {
  const [sum, setSum] = useState<number | null>(invoice.sum);

  function updateCard(
    card: TBoardCard,
    data: { title: string; deadline: string }
  ) {
    const invoiceToUpdate = card.invoices.find(item => item.pk === invoice.pk);
    if (invoiceToUpdate) {
      invoice.title = data.title;
    }
    return card;
  }


  async function handleSubmit(e: SyntheticEvent) {
    if (!sum) return; // add ui/ux
    e.preventDefault();
    const target = e.target as typeof e.target & {
      title: { value: string };
    };
    try {
      onClose();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Edit Invoice</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id="task-form">
          <TextareaAutosize
            style={{
              fontSize: '18px',
              minWidth: '100%',
              fontFamily: 'Roboto',
            }}
            defaultValue={invoice.title}
            placeholder="Description"
            minRows={5}
            name="title"
          />
          <InputWrapper>
            <FormLabel>Sum</FormLabel>
            <TextField
              type="number"
              value={sum}
              onChange={e => setSum(Number(e.target.value))}
              placeholder="Sum"
            />
          </InputWrapper>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="task-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
