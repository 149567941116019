import { TBoard, TCards } from '../components/board';
import { TBoardData } from '../pages/board';

export function prepareData(
  boardData: TBoardData,
  cards: TCards,
  cardIdsObj: Record<string, string[]>
): TBoard {
  const newColumns = boardData.columns.reduce((columns, column) => {
    const newColumn = {
      ...column,
      cardIds: cardIdsObj[`column-${column.pk}`] || [],
    };
    return {
      ...columns,
      [`column-${newColumn.pk}`]: {
        ...newColumn,
        id: `column-${newColumn.pk}`,
      },
    };
  }, {});

  const columnOrder = boardData.columns.map(column => `column-${column.pk}`);

  return {
    ...boardData,
    columns: newColumns,
    cards,
    columnOrder,
  };
}

export function hashCode(str: string) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function intToRGB(i: number) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
}
