import { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { TaskList, TaskHeader, Left, Right } from './styles';
import { AddTaskDialog } from './add-task-modal';
import {
  useBoardContext,
  useCardContext,
  useImageContext,
} from '../../contexts';
import useAPI from '../../hooks/useAPI';
import { Attachments } from './comment';
import { TAttachment } from '../../services/ApiService';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useTextWithUrls } from '../../hooks/useEditableWithUrls';
import styled from 'styled-components';
import { TTags } from '../card';
import { AddTagsDialog } from './add-tags-modal';
import EditTag from './edit-tag';

export const GetText = ({
                          text,
                          maxLength = 80,
                        }: {
  text: string;
  maxLength?: number;
}) => {
  const [more, setMore] = useState(text.length < maxLength);

  const render = useTextWithUrls(
    more
      ? text
      : text.slice(0, maxLength) + (text.length > maxLength ? '...' : '')
  );

  return (
    <div>
      {render()}
      {text.length > maxLength &&
      (!more ? (
        <span
          style={{ color: 'green', marginLeft: '10px', cursor: 'pointer' }}
          onClick={() => setMore(true)}
        >
            more
          </span>
      ) : (
        <span
          style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}
          onClick={() => setMore(false)}
        >
            less
          </span>
      ))}
    </div>
  );
};

export default function Tags() {
  const { card, setCard } = useCardContext();

  const [addTagsOpen, setOpen] = useState(false);

  const { updateBoard, board } = useBoardContext();

  function removeTagFromCard(id: number) {
    if (card) {
      const updatedBoard = { ...board };
      const updatedCard = board.cards[card.id];
      const tags = updatedCard.tags.filter(task => task.pk !== id);
      updatedCard.tags = tags;
      const dialogCard = { ...card };
      dialogCard.tags = tags;
      setCard(dialogCard);
      updateBoard(updatedBoard);
    }
  }

  const handleAddTag = () => setOpen(true);
  const [show, setShow] = useState(0);
  const handleClose = () => setShow(0);
  const deleteTag = useAPI('deleteTag');
  async function handleDelete() {
    try {
      await deleteTag(show);
      removeTagFromCard(show);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  }

  const [edit, setEdit] = useState<TTags | null>(null);

  return (
    <>
      {edit && <EditTag tag={edit} onClose={() => setEdit(null)} />}
      {!!show && (
        <Dialog onClose={() => setShow(0)} open={!!show}>
          <DialogTitle sx={{ width: '300px' }}>Delete Tag ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {card && (
        <AddTagsDialog
          open={addTagsOpen}
          onClose={() => setOpen(false)}
          card={card}
        />
      )}
      <TaskHeader>
        <InputLabel>Tags</InputLabel>
        <Button variant="outlined" onClick={handleAddTag}>
          Add Tag
        </Button>
      </TaskHeader>
      <TaskList>
        {card?.tags.map(tag => (
          <Fragment key={tag.pk}>
            <Wrapper>
              <Left>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <ListItemText
                    primary={<GetText text={tag.title} />}
                  />
                </div>
              </Left>
              <Right>
                <IconButton onClick={() => setEdit(tag)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => setShow(tag.pk)}>
                  <CloseIcon />
                </IconButton>
              </Right>
            </Wrapper>
            <Divider />
          </Fragment>
        ))}
      </TaskList>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`;
