import { useLocation, useNavigate } from 'react-router-dom';

export function End() {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) navigate('/');

  return (
    <div>
      <p>We sent confiramtion link to your email:</p>
      <p>
        {
          /**
           * @ts-expect-error fix this
           */
          location.state?.email
        }
      </p>
    </div>
  );
}
