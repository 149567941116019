import styled from 'styled-components'

export const MessagesBlock = styled.div`
  padding: 10px;
  flex: 1 1 0;
  -ms-flex: 1 1 1%;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  &::-webkit-scrollbar {
    width: 0;
  }
`

export const MessagesBlockDay = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`

export const Message = styled.div`
  padding: 5px 15px;
  font-size: 16px;
  line-height: 150%;
  color: rgb(51, 51, 51);
  border-radius: 20px;
  margin-bottom: 5px;
  white-space: pre-line;
  text-align: left;
  max-width: 350px;
  word-break: break-all;
`

export const MessageRight = styled(Message)`
  background-color: #0000b3;
  margin-left: auto;
  color: white;
  &:has(audio) {
    background: none;
    padding: 5px 0;
  }
`

export const MessageLeft = styled(Message)`
  background-color: #BFCFFF;
  margin-right: auto;
  &:has(audio) {
    background: none;
    padding: 5px 0;
  }
`

export const DateMessage = styled.div`
  padding: 0;
  font-size: 10px;
  margin-bottom: 5px;
  color: #828282;
`

export const DateMessageRight = styled(DateMessage)`
  margin-left: auto;
`

export const DateMessageLeft = styled(DateMessage)`
  margin-right: auto;
`

export const FileBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`
