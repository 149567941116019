import { useEffect, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import api from '../services/ApiService';
import useAPI from './useAPI';
import useStateCallback from './useStateCallback';

export type TProfile = {
  email: string;
  first_name: string;
  last_name: string;
  pk: number;
  username: string;
};

export default function useAuth() {
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [profile, setProfile] = useStateCallback<TProfile | null>(null);
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem('crm_token');
    api.removeToken();
    setLoggedIn(false);
    location.reload();
  }

  const getProfileSafe = useAPI('getProfile', handleLogout);

  async function getProfile(to?: To) {
    try {
      const profile = await getProfileSafe();
      setProfile(profile, () => {
        console.log(to);
        to && navigate(to);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('crm_token');
    if (token) {
      api.setToken(token);
      setLoggedIn(true);
      getProfile();
    } else {
      setLoggedIn(false);
      setProfile({} as TProfile);
    }
  }, []);

  function handleSignin(to: To) {
    console.log(to);
    setLoggedIn(true);
    getProfile(to);
  }

  return {
    loggedIn,
    profile,
    setProfile,
    handleSignin,
    handleLogout,
  };
}
