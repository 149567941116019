import { useEffect, useState } from 'react';
import { TBoard } from '../components/board';
import { TMember } from '../components/edit-board-modal';
import api, { TType } from '../services/ApiService';

// {
//   "pk": 6,
//   "email": "bydigdef+5@gmail.com",
//   "username": "digdef5",
//   "first_name": "test5",
//   "last_name": "Сергей"
// }

export type TAssigner = {
  pk: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  cards: Record<string, boolean>;
};

export default function useMembers(board: TBoard | undefined) {
  const [members, setMembers] = useState<TMember[]>([]);
  const [assigners, setAssigners] = useState<TAssigner[]>([]);

  useEffect(() => {
    if (board) {
      (async function () {
        try {
          const data = await api.getSharedMembers(board.pk);
          const list = await api.getMembers(board.code);
          setAssigners(list);
          setMembers(data);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [board]);

  const isMember = (type: TType, id: number) =>
    members.some(member => member.pk === id && member.share_type === type);

  const isAdmin = (id: number) => isMember('ADMIN', id);
  const isEditor = (id: number) => isMember('EDITOR', id);
  const isCommentator = (id: number) => isMember('COMMENTATOR', id);

  return {
    isAdmin,
    isEditor,
    isCommentator,
    assigners,
  };
}
