import styled from 'styled-components';

export const Container = styled.div<{ isDraggingOver: boolean }>`
  padding: 20px;
  margin-left: 10px;
  min-width: 300px;
  background-color: white;
  border-radius: 10px;
  max-height: 100%;
  height: fit-content;
  max-width: 300px;
  &:first-of-type {
    margin-left: 0;
  }
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};
  @media (max-width: 1440px) {
    min-width: 250px;
    padding: 20px 10px;
  }
`;

export const Title = styled.div`
  margin: 0;
  font-size: 16px;
  color: #29465b;
  font-weight: 500;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
`;

export const CardList = styled.div<{ isDraggingOver?: boolean }>`
  padding: 8px;
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};
  flex-grow: 1;
  /* max-height: 100%; */
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;

export const Header = styled.div`
  /* display: flex; */
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1440px) {
    svg {
      width: 20px;
    }
  }
`;
