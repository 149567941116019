import styled from 'styled-components';
import { hashCode, intToRGB } from '../services/utils';

export const Avatar = ({ name }: { name: string }) => (
  <Container color={intToRGB(hashCode(name))}>{name.slice(0, 2)}</Container>
);

const Container = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background-color: ${props => props.color};
  text-transform: uppercase;
`;
