import { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type TProps = {
  name: string;
  open: boolean;
  handleClose: () => void;
  edit: (name: string) => void;
};

export function FormDialog({ name, open, handleClose, edit }: TProps) {
  const [value, setValue] = useState(name);

  const handleEdit = () => {
    if (!value) return;
    edit(value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Column Name</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Column Name"
          type="text"
          fullWidth
          variant="standard"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleEdit}>Edit</Button>
      </DialogActions>
    </Dialog>
  );
}
