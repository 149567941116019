import { DragDropContext } from 'react-beautiful-dnd';
import { Board, TBoard, TCards } from '../components/board';
import { TColumn } from '../components/column';
import {
  BoardContext,
  CardContext,
  ImageContext,
  MembersContext,
  NewCardContext,
  useAuthContext,
} from '../contexts';
import { CardDialog } from '../components/card-modal';
import { useCardDialog } from '../hooks';
import { NewCardDialog } from '../components/new-card-modal';
import { useEffect, useState } from 'react';
import api from '../services/ApiService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../components/header/header';
import { BoardHeader } from '../components/board-header';
import { prepareData } from '../services/utils';
import { TBoardCard } from '../components/card';
import { DndContainer } from './styles';
import useDrag from '../hooks/useDrag';
import Image from '../components/image';
import useMembers from '../hooks/useMembers';

export type TBoardData = {
  pk: number;
  name: string;
  owner: number;
  code: string;
  columns: TColumn[];
  note: string;
  view_for_all: boolean;
  color_background: string;
};

export function BoardPage() {
  const { code } = useParams();
  // hook
  const [board, setBoard] = useState<TBoard>();
  const { profile } = useAuthContext();

  useEffect(() => {
    console.log('backgorund color changed: ', board?.color_background);
    if (board?.color_background) {
      document.body.style.background = board.color_background;
    }
    return () => {
      document.body.style.background = '';
    };
  }, [board?.color_background]);

  function updateBoard(newBoard: TBoard) {
    setBoard(newBoard);
  }

  const navigate = useNavigate();
  const location = useLocation();

  const members = useMembers(board);
  //
  useEffect(() => {
    (async function () {
      try {
        if (code) {
          const data = await api.getBoard(code);

          const cards: TCards = {};

          const cardIds: Record<string, string[]> = {};

          const promises = data.columns.map(async column => {
            const { results }: { results: TBoardCard[] } =
              await api._makeRequest(
                `board/api/cards/?board=${code}&status=${column.pk}`,
                'GET'
              );
            if (!cardIds[`column-${column.pk}`]) {
              cardIds[`column-${column.pk}`] = [];
            }
            results.forEach(card => {
              cards[`card-${card.pk}`] = { ...card, id: `card-${card.pk}` };
              cardIds[`column-${column.pk}`].push(`card-${card.pk}`);
            });
          });

          await Promise.all(promises);

          const prepare = prepareData(data, cards, cardIds);
          setBoard(prepare);

          console.log('cards', cards);
        }
      } catch (error: any) {
        console.log(error);
        if (error?.status === 403) {
          console.log(profile);
          if (Object.getOwnPropertyNames(profile).length !== 0) {
            navigate('/');
          } else {
            navigate('/signin', { state: { from: location } });
          }
        }
      }
    })();
  }, [code, location, navigate]);

  const onDragEnd = useDrag({ board, updateBoard });

  const dialog = useCardDialog();

  const [open, setOpen] = useState(0);
  const [image, setImage] = useState('');

  if (!board) return null;
  return (
    <BoardContext.Provider value={{ board, updateBoard }}>
      <MembersContext.Provider value={members}>
        <Image image={image} setImage={(image: string) => setImage(image)} />
        <Header />
        <ImageContext.Provider
          value={{
            setImage: (image: string) => setImage(image),
          }}
        >
          <CardContext.Provider value={{ ...dialog }}>
            <NewCardContext.Provider
              value={{ open, setOpen: (open: number) => setOpen(open) }}
            >
              <CardDialog {...dialog} />
              <NewCardDialog
                open={open}
                onClose={() => setOpen(0)}
                owner={board.owner}
              />
              <DragDropContext onDragEnd={onDragEnd}>
                <BoardHeader code={board.code} />
                <DndContainer>
                  <Board board={board} />
                </DndContainer>
              </DragDropContext>
            </NewCardContext.Provider>
          </CardContext.Provider>
        </ImageContext.Provider>
      </MembersContext.Provider>
    </BoardContext.Provider>
  );
}
