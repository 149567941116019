import { IconButton } from '@mui/material';
import { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useBoardContext, useNewCardContext } from '../../contexts';
import api from '../../services/ApiService';
import { Card, TBoardCard } from '../card';
import { FormDialog } from './edit-name-dialog';
import {
  Container,
  Title,
  CardList,
  Header,
  TitleWrapper,
  Buttons,
} from './styles';

import { toast } from 'react-toastify';

export type TColumn = {
  pk: number;
  title: string;
  cardIds: string[];
  id: string;
  order: number;
  fixed: boolean;
};

export function Column({
  column,
  cards,
  index,
}: {
  column: TColumn;
  cards: TBoardCard[];
  index: number;
}) {
  const { updateBoard, board } = useBoardContext();
  const { setOpen: setOpenNewCard } = useNewCardContext();
  function removeColumn(id: string, index: number) {
    console.log('delete column');
    const newBoard = { ...board };
    delete newBoard.columns[id];
    newBoard.columnOrder.splice(index, 1);
    updateBoard(newBoard);
  }

  async function handleDelete() {
    try {
      await api.deleteColumn(column.pk);
      removeColumn(column.id, index);
    } catch (error: any) {
      console.log(error);
      if (error?.status === 403) {
        toast.error('Forbidden');
      }
    }
  }

  function updateColumn(name: string) {
    const newBoard = { ...board };
    newBoard.columns[column.id].title = name;
    updateBoard(newBoard);
  }

  async function handleEdit(name: string) {
    try {
      await api.editColumn(column.pk, name);
      updateColumn(name);
      handleClose();
    } catch (error: any) {
      console.log(error);
      if (error?.status === 403) {
        toast.error('Forbidden');
      }
    }
  }

  // hook
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //

  return (
    <>
      <FormDialog
        open={open}
        handleClose={handleClose}
        name={column.title}
        edit={handleEdit}
      />
      <Draggable
        draggableId={column.id}
        index={index}
        isDragDisabled={column.fixed}
      >
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDragging}
          >
            <Header {...provided.dragHandleProps}>
              <TitleWrapper>
                <Title>{column.title}</Title>
                <Buttons>
                  <IconButton onClick={handleClickOpen} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setOpenNewCard(column.pk)}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton onClick={handleDelete} color="primary">
                    <DeleteIcon />
                  </IconButton>
                </Buttons>
              </TitleWrapper>
            </Header>
            <Droppable droppableId={column.id} type="card">
              {(provided, snapshot) => (
                <CardList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {cards.map((card, index) => (
                    <Card card={card} index={index} key={`card-${card.pk}`} />
                  ))}
                  {provided.placeholder}
                </CardList>
              )}
            </Droppable>
          </Container>
        )}
      </Draggable>
    </>
  );
}
