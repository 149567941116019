import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  useMediaQuery,
} from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import EventNoteIcon from '@mui/icons-material/EventNote';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import PieChartIcon from '@mui/icons-material/PieChart';

import {
  useBoardContext,
  useCardContext,
  useImageContext,
} from '../../contexts';
import api, { TAttachmentsData, TAttachment } from '../../services/ApiService';
import useAPI from '../../hooks/useAPI';

import { TBoardCard } from '../card';
import Note from '../note';
import MembersModal from '../members';
import { Attachments } from '../card-modal/comment';

import * as S from './styles';
import SettingsModal from '../settings-modal';
import { Link } from 'react-router-dom';

type TData = {
  results: TBoardCard[];
};

const isAdmin = () => true;

export function DialogHeader({
  onClose,
  title,
  children,
}: {
  onClose: () => void;
  title: string;
  children?: ReactNode;
}) {
  return (
    <DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          left: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <p style={{ margin: '10px 0 0' }}>{title}</p>
        {children}
      </div>
    </DialogTitle>
  );
}

export function BoardHeader({ code }: { code: string }) {
  const [query, setQuery] = useState('');
  const [data, setData] = useState<TData>();

  const { setCard } = useCardContext();

  function handleClick(card: TBoardCard) {
    setCard({ ...card, index: 1 });
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value);
  }

  useEffect(() => {
    (async function () {
      if (query) {
        const data = await api.search(code, query);
        setData(data);
      } else {
        setData(undefined);
      }
    })();
  }, [query, code]);

  const [popup, setPopup] = useState(false);

  const openPopup = () => setPopup(true);

  const closePopup = () => setPopup(false);

  const [open, setOpen] = useState(false);
  const [openNote, setOpenNote] = useState(false);

  const getAttachments = useAPI('getAttachments');

  const { board, updateBoard } = useBoardContext();
  const [attachments, setAttachments] = useState<TAttachment[]>([]);

  async function handleOpen() {
    setOpen(true);
    try {
      const { results }: TAttachmentsData = await getAttachments(board.code);
      setAttachments(results);
    } catch (error) {
      console.log(error);
    }
  }

  function handleOpenNote() {
    setOpenNote(true);
  }

  const { setImage } = useImageContext();

  const [membersModal, setMembersModal] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    board.note
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(board.note)))
      : EditorState.createEmpty()
  );

  async function handleSave() {
    const newNote = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    try {
      const { note } = await api.editBoard(board.code, { note: newNote });
      const newBoard = { ...board };
      newBoard.note = note;
      updateBoard(newBoard);
      setOpenNote(false);
    } catch (error: any) {
      console.log(error);
      if (error?.status === 403) {
        toast.error('Forbidden');
      }
    }
  }

  const [settings, setSettings] = useState(false);
  const matches = useMediaQuery('(max-width:480px)');

  return (
    <S.Container>
      {settings && (
        <SettingsModal open={settings} onClose={() => setSettings(false)} />
      )}
      {membersModal && (
        <MembersModal
          open={membersModal}
          onClose={() => setMembersModal(false)}
        />
      )}
      {open && (
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogHeader onClose={() => setOpen(false)} title="Attachments" />
          <DialogContent>
            {attachments.length ? (
              <Attachments attachments={attachments} setImage={setImage} />
            ) : (
              <p style={{ fontSize: '18px' }}>No Attachments</p>
            )}
          </DialogContent>
        </Dialog>
      )}
      {openNote && (
        <Dialog onClose={() => setOpenNote(false)} open={openNote}>
          <DialogHeader onClose={() => setOpenNote(false)} title="Note" />
          <DialogContent
            sx={{ minWidth: matches ? '100%' : '600px', minHeight: '250px' }}
          >
            <Note editorState={editorState} setEditorState={setEditorState} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
          </DialogActions>
        </Dialog>
      )}
      <S.Header style={{ display: 'flex' }}>
        <S.Bar>
          <TextField
            size="small"
            value={query}
            onChange={onChange}
            fullWidth
            color="primary"
            placeholder="Search"
            onFocus={openPopup}
          />
          <S.IconWrapper>
            <SearchIcon color="primary" />
          </S.IconWrapper>
        </S.Bar>
        {popup && data && data?.results?.length > 0 && (
          <S.Result>
            <S.Wrapper role="dialog" onClick={closePopup} />
            <DialogTitle color="Highlight" textAlign="center">
              Search Results:
            </DialogTitle>
            {data.results.map(card => (
              <S.Row onClick={e => handleClick(card)} key={card.pk}>
                {card.topic ? (
                  <span>Title: {card.topic.title}</span>
                ) : (
                  <span>
                    Full name: {card.card?.full_name}, Project name:{' '}
                    {card.card?.project_name}
                  </span>
                )}
              </S.Row>
            ))}
          </S.Result>
        )}
      </S.Header>
      <S.ToolBar>
        {isAdmin() && (
          <S.AttachmentsBtn onClick={() => setMembersModal(true)}>
            <GroupIcon color="primary" />
          </S.AttachmentsBtn>
        )}
        <S.AttachmentsBtn onClick={handleOpen}>
          <AttachFileIcon color="primary" />
        </S.AttachmentsBtn>
        <S.AttachmentsBtn onClick={handleOpenNote}>
          <EventNoteIcon color="primary" />
        </S.AttachmentsBtn>
        <S.AttachmentsBtn onClick={() => setSettings(true)}>
          <SettingsIcon color="primary" />
        </S.AttachmentsBtn>
        <S.AttachmentsBtn>
          <Link to="/board/statistics">
            <PieChartIcon color="primary" />
          </Link>
        </S.AttachmentsBtn>
      </S.ToolBar>
    </S.Container>
  );
}
