import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton,
  TextareaAutosize
} from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { useUpdateCard } from '../../hooks';
import useAPI from '../../hooks/useAPI';
import { TBoardCard, TTags } from '../card';
import CloseIcon from '@mui/icons-material/Close';
import { Wrapper } from '../new-card-modal/styles';

type TProps = { tag: TTags; onClose: () => void };

export default function EditTag({ tag, onClose }: TProps) {
  const editTagSafe = useAPI('editTags');

  function updateCard(
    card: TBoardCard,
    data: { title: string }
  ) {
    const tagToUpdate = card.tags.find(item => item.pk === tag.pk);
    if (tagToUpdate) {
      tag.title = data.title;
    }
    return card;
  }

  const update = useUpdateCard(updateCard);

  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      title: { value: string };
    };
    try {
      const data = await editTagSafe(tag.pk, {
        title: target.title.value,
      });
      update({ ...data });
      onClose();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 10,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <p style={{ margin: '0 25px' }}>
          Edit Tag
        </p>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id="tag-form">
          <TextareaAutosize
            style={{
              fontSize: '18px',
              minWidth: '100%',
              fontFamily: 'Roboto',
            }}
            defaultValue={tag.title}
            placeholder="Description"
            minRows={5}
            name="title"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="tag-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
