import { ChangeEvent, useState } from 'react';

export default function useFiles() {
  const [files, setFiles] = useState<File[] | null>(null);
  const handleDelete = (idx: number) => {
    setFiles(files => files?.filter((_, index) => index !== idx) || []);
  };
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const input = e.currentTarget;
    if (!input.files?.length) {
      return;
    }
    setFiles(old => [...(old || []), ...Array.from(input.files || [])]);
  }

  return {
    files,
    setFiles,
    handleDelete,
    onChange,
  };
}
