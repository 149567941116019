import { toast } from 'react-toastify';
import { useAuthContext } from '../contexts';
import api, { Api } from '../services/ApiService';

export default function useAPI(methodName: string, action?: any) {
  const { onLogout } = useAuthContext();

  async function fetch(...params: any[]) {
    try {
      // const method = api[methodName as keyof Api].(...params);
      // if (typeof method === 'function') {
      //   //@ts-expect-error fix thiss
      //   return await method(...params);
      // }

      // @ts-expect-error fix this
      return await api[methodName as keyof Api](...params);
    } catch (error: any) {
      if (error.status === 401) {
        if (action) {
          action();
        } else {
          onLogout();
        }
        return;
      }
      if (error.status === 500) {
        toast.error('500: Internal Server Error');
        return;
      }
      console.log(error);
      if (typeof error.json === 'function') {
        const err = await error.json();
        return Promise.reject(err);
      }
      return Promise.reject(error);
    }
  }

  return fetch;
}
