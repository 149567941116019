import { Button, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, To, useLocation } from 'react-router-dom';
import useAPI from '../../hooks/useAPI';
import api from '../../services/ApiService';
import { InputWrapper } from '../../ui/styles';
import { Box, Container, LogoImg } from './styles';
import logo from '../../logo.png';
import styled from 'styled-components';

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export function Signin({ onSignin }: { onSignin: (to: To) => void }) {
  const [err, setError] = useState('');
  const location = useLocation();
  const state = location.state as { from?: string } | undefined;
  const signIn = useAPI('signIn');

  useEffect(() => {
    const tokenGetParameter = useQuery().get('token');
    if (tokenGetParameter) {
      localStorage.setItem('crm_token', tokenGetParameter);
      api.setToken(tokenGetParameter);
      onSignin(state?.from ? state.from : '/');
    }
  }, []);

  async function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
    };
    const body = {
      username: target.username.value,
      password: target.password.value,
    };
    try {
      const { token } = await signIn(body);
      localStorage.setItem('crm_token', token);
      api.setToken(token);
      onSignin(state?.from ? state.from : '/');
    } catch (error: any) {
      console.log(error);
      if (error?.non_field_errors) {
        let errorString = '';
        error?.non_field_errors.forEach(
          (error: string) => (errorString += error + '\n')
        );
        setError(errorString);
      }
    }
  }

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit}>
        <Box>
          <LogoImg>
            <img src={logo} alt="logo" />
          </LogoImg>
          {err && <p style={{ color: 'red', maxWidth: '300px' }}>{err}</p>}
          <InputWrapper>
            <InputLabel>username or email</InputLabel>
            <TextField name="username" required fullWidth />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>password</InputLabel>
            <TextField type="password" name="password" required fullWidth />
          </InputWrapper>
          <Button
            variant="outlined"
            type="submit"
            sx={{ marginTop: '15px' }}
            fullWidth
          >
            Signin
          </Button>
          <p>
            <Link to="/signup">Signup</Link>
          </p>
        </Box>
      </form>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  @media (max-width: 480px) {
    margin-top: 100px;
  }
`;
