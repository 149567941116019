import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 500;
  color: #191919;
  @media (max-width: 1440px) {
    font-size: 24px;
  }
`;

export const ColumnList = styled.div`
  display: flex;
  margin-top: 10px;
  max-width: calc(100vw - 10px);
  overflow-x: auto;
  height: calc(100vh - 200px);
  padding-right: 10px;
  padding-bottom: 20px;
  @media (max-width: 480px) {
    height: calc(100vh - 300px);
  }
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;

    &:hover {
      background: rgba(255, 255, 255, 1);
    }
  }
`;

export const AddColumn = styled.div`
  /* border: 1px solid black; */
  padding: 10px 20px;
  margin-left: 10px;
  height: fit-content;
  background-color: #ffffff3d;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
`;
