import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Completed', 'Overdue', 'New'],
  datasets: [
    {
      label: 'Cards',
      data: [12, 19, 5],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        // 'rgba(75, 192, 192, 1)',
        // 'rgba(153, 102, 255, 1)',
        // 'rgba(255, 159, 64, 1)',
      ],
      // borderColor: [
      //   'rgba(255, 99, 132, 1)',
      //   'rgba(54, 162, 235, 1)',
      //   'rgba(255, 206, 86, 1)',
      //   'rgba(75, 192, 192, 1)',
      //   'rgba(153, 102, 255, 1)',
      //   'rgba(255, 159, 64, 1)',
      // ],
      // borderWidth: 1,
    },
  ],
};

export const data1 = {
  labels: ['Sales1', 'Sales2'],
  datasets: [
    {
      label: 'Sales',
      data: [1200, 1900],
      backgroundColor: [
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        // 'rgba(255, 99, 132, 1)',
        // 'rgba(54, 162, 235, 1)',
        // 'rgba(255, 206, 86, 1)',
        // 'rgba(75, 192, 192, 1)',
      ],
      // borderColor: [
      //   'rgba(255, 99, 132, 1)',
      //   'rgba(54, 162, 235, 1)',
      //   'rgba(255, 206, 86, 1)',
      //   'rgba(75, 192, 192, 1)',
      //   'rgba(153, 102, 255, 1)',
      //   'rgba(255, 159, 64, 1)',
      // ],
      // borderWidth: 1,
    },
  ],
};

export default function Statistics() {
  const [dateRange, setDateRange] = useState<(Date | null)[]>([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <Flex>
      <div>
        <FlexRow>
          <Title>Tasks: </Title>
          <Value>100</Value>
        </FlexRow>
        <FlexRow>
          <Title>Completed Tasks: </Title>
          <Value>45</Value>
        </FlexRow>
        <FlexRow>
          <Title>Invoices: </Title>
          <Value>24</Value>
        </FlexRow>
        <FlexRow>
          <Title>Paid Invoices: </Title>
          <Value>13</Value>
        </FlexRow>
      </div>
      <div>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={update => {
            setDateRange(update);
          }}
          isClearable={true}
        />
        <PieWrapper>
          <Pie data={data} />
        </PieWrapper>
      </div>
      {/* <PieWrapper>
        <Pie data={data1} />
      </PieWrapper> */}
    </Flex>
  );
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PieWrapper = styled.div`
  width: minmax(500px, 40%);
  @media (max-width: 768px) {
    width: 70%;
    :not(:first-of-type) {
      margin-top: 50px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const FlexRow = styled.div`
  display: flex;
`;

const Base = styled.div`
  font-size: 20px;
  line-height: 1.4;
  color: #29465b;
`;

const Title = styled(Base)``;

const Value = styled(Base)`
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
`;
