import { useEffect, useState } from 'react';
import { TDCard, useBoardContext, useCardContext } from './contexts';

export function useCardDialog() {
  const [card, setCard] = useState<TDCard | null>(null);

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
    setCard(null);
  };

  useEffect(() => {
    if (card) {
      setOpen(true);
    }
  }, [card]);

  return {
    open,
    onClose,
    card,
    setCard: (card: TDCard | null) => {
      setCard(card);
    },
  };
}

export function useUpdateCard(callback: any) {
  const { setCard, card } = useCardContext();
  const { updateBoard, board } = useBoardContext();

  const updateCard = (data: unknown) => {
    if (card) {
      const newCard = { ...card };
      const updated = callback(newCard, data);
      setCard(updated);
      const newBoard = { ...board };
      newBoard.cards[updated.id] = updated;
      updateBoard(newBoard);
    }
  };

  return updateCard;
}
