import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BoardContainer = styled(Link)`
  padding: 10px;
  /* border: 1px solid grey; */
  border-radius: 10px;
  width: fit-content;
  margin-top: 10px;
  background-color: #fff;
  width: 100%;
  text-decoration: none;
`;

export const Text = styled.p`
  color: #29465b;
`;

export const Title = styled.p`
  color: #29465b;
  font-size: 20px;
  font-weight: 500;
`;

export const Container = styled.div`
  height: 100vh;
`;

export const ListContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Btn = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  margin: 10px 20px 25px 20px;
  background-color: #fff;
  color: #29465b;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

export const DndContainer = styled.div`
  display: flex;
  padding: 10px;
  max-height: calc(100vh - 100px);
`;

export const H2 = styled.h2`
  margin: 0 20px;
`;

export const AddForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* & span {

  } */
  border-bottom: 1px solid #000;
  padding: 10px 20px;
`;

export const List = styled.div`
  margin-top: 20px;
`;

export const BoardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
